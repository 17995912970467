import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../../../../utils/apiUtilis.js";

export const getClasses = createAsyncThunk(
    "api/v1/administration/academics/class",
    async (classData, {rejectWithValue}) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/v1/class/get/class`,
                classData
            );
            console.log("This is response", response.data)
            return response.data;

        } catch (err) {
            console.log("This is error", err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null
} ;

const getClassesSlice = createSlice({
    name: "getclasses",
    initialState,
    extraReducers: {
        [getClasses.pending]: (state) => {
            state.loading = true;

        },
        [getClasses.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
        },
        [getClasses.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
        }
    }
} );

export default getClassesSlice.reducer;