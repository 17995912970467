import {useDispatch, useSelector} from 'react-redux';
import { useState } from 'react';
import { Modal } from 'antd';
import { addCourse } from '../../../../Features/Redux/Administration/Academics/Course/Addcourse.slice';
import CourseList from './GetAllCourses';
function AddCourse() {
    const dispatch = useDispatch();
    const [courseName, setCourseName] = useState('');
    const [error, setError] = useState(null);
    const {status,errors,loading} = useSelector((state) => state. addcourse);


    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation
        if(!courseName) {
            setError('Class name is required');
            return;
        }

        dispatch(addCourse({ name: courseName }));
        setCourseName('');
        setError(null);
    }
    // console.log("This is the status",status)
    // console.log ("This is the error",errors)
    //   console.log("This is the loading",loading)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
    return (

        <div>
            {/* <div>
                <form onSubmit={handleSubmit}>
                    {error && <p>{error}</p>}
                    <input
                        type="text"
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                        placeholder="Enter course name"
                    />
                    <button type="submit">Add Course</button>
                </form>
            </div> */}
            <CourseList/>
            <div className="p-4 w-64 ">
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 focus:outline-none"
        onClick={showModal}
      >
        Add Course
      </button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="p-10"
      >
        <h1 className="text-2xl text-center">Add Course</h1>
        <form onSubmit={handleSubmit}>
                    {error && <p>{error}</p>}
          <div className="mt-10">
            <label className="pl-20 text-lg ">Course name</label>
            <input
              className="w-2/3 mx-20 mb-10 p-2 mt-2 border rounded focus:outline-none focus:border-blue-500"
              type="text"
              value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
              placeholder="Enter course name"
            />
          </div>

          <button
            className="w-full p-2 mt-10 mb-5 text-white rounded-md"
            style={{ backgroundColor: "rgba(19, 61, 103, 0.9)" }}
            type="submit">Add Course
          </button>
        </form>
      </Modal>
    </div>
        </div>
    );
}

export default AddCourse;