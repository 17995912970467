import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../utils/apiUtilis";
import Cookies from "js-cookie";

// Create a thunk for fetching data from the API
export const parentsLogin = createAsyncThunk(
  "api/v1/parent/login",
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/parent/login`,
        user
      );
      // Cookies.set("email", response?.data?.user?.email, { expires: 7 }); // Expires after 7 days
      Cookies.set("email", response?.data?.user?.email, {
        expires: 2,
        // secure: true,
      }); //, httpOnly: true, sameSite: "Lax"
      Cookies.set("token", response.data?.token, { expires: 2 });
      Cookies.set("role", response.data.roleId, { expires: 2 });
      const { email } = user;
      return {
        ...response.data,
        credentials: {
          email,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const parentsVerify = createAsyncThunk(
  "api/v1/parent/login/verifyotp",
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/parent/login/verifyotp`,
        user
      );
      Cookies.set("token", response?.data?.token, { expires: 2, secure: true }); //, httpOnly: true, sameSite: "Lax"
      Cookies.set("role", response?.data?.roleId, { expires: 2, secure: true }); //, httpOnly: true, sameSite: "Lax"
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const parentsLoginSlice = createSlice({
  name: "parent",
  initialState: {
    users: [],
    status: null,
    Error: null,
    loading: false,
    errors: false,
  },
  extraReducers: {
    [parentsLogin.pending]: (state) => {
      // state.status = "loading.....";
      state.loading = true;
    },
    [parentsLogin.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = "success";
      state.success = true;
    },
    [parentsLogin.rejected]: (state, action) => {
      state.status = "failed";
      state.Error = action.payload;
      state.errors = true;
    },
    [parentsVerify.pending]: (state, action) => {
      state.status = "loading.....";
    },
    [parentsVerify.rejected]: (state, action) => {
      state.status = "failed";
      state.Error = action.payload;
    },
    [parentsVerify.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = "success";
    },
  },
});

export default parentsLoginSlice.reducer;
