import SideBar from "../../Components/SideBar/SideBar";
import Content from "../../Components/Content/Content";
import NavBar from "../../Components/Dashboard/Header";
import StudentTable from "./StudentTable";
import ClassesList from "../../Components/Administration/Academics/Class/GetClasses";
import "../../styles/interface.scss";
import Admin from "../../Components/admin/admin";

function ListStudent() {
  return (
    <>



      <Admin>
        <ClassesList />
        <p className="text-lg font-semibold">List of Students</p>
        <div className="bg-[#133d67] flex justify-center items-center">
        <p className="text-lg text-white">
          Manage all All Students
        </p>
        </div>
        <StudentTable />
      </Admin>


    </>
  );
}

export default ListStudent;
