import axios from "axios"
import {BASE_URL} from "../../../../../../utils/apiUtilis";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";


export const getAllMidterms = createAsyncThunk(
    "api/v1/administration/academics/term/midterm/getallmidterms",
    async (midtermData, {rejectWithValue}) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/v1/termandmidterm/midterms`,
                midtermData
            );
            console.log("This is response", response.data.data)
            return response.data.data;

        } catch (err) {
            console.log("This is error", err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    });

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null,
}

const getAllMidtermsSlice = createSlice({
    name: "getallmidterms",
    initialState,

    extraReducers: {
        [getAllMidterms.pending]: (state) => {
            state.loading = true;

        },
        [getAllMidterms.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;

        },
        [getAllMidterms.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
            state.data= [];
        },
    }

});

export default getAllMidtermsSlice.reducer;