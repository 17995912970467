import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {getAllstudents} from "../../Features/Redux/Administration/Academics/Student/GetAllStudent.slice";
import { COLUMNS } from "../../Components/StudentMarks/columns";
import Table from "../../Components/StudentTable/";
import {CirclesWithBar} from "react-loader-spinner";

const StudentMarkTable = () => {
  const dispatch = useDispatch();
  const { data: allusers, status,loading } = useSelector((state) => state.getallstudent);
  useEffect(() => {
    dispatch(getAllstudents());
  }, [dispatch]);

  const columns = useMemo(() => COLUMNS, []);
  const data = allusers?.data || [];
  console.log ("This is all student fromm the table", allusers);

  if (loading) {
    return <div><p>
      <CirclesWithBar
          type="Oval"
          color="#06a9eaec"
          height={200}
          align="center"
          width={160}
      /></p></div>;
  }

  if (status === "failed") {
    return <div>Error occurred while fetching users.</div>;
  }

  return <Table columns={columns} data={data} />;
};

export default StudentMarkTable;
