import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../Features/Redux/User/viewUser/view.slice";
import { COLUMNS } from "../../Components/Table/columns";
import Table from "../../Components/Table/";
import {CirclesWithBar} from "react-loader-spinner";

const UserTable = () => {
  const dispatch = useDispatch();
  const { allusers, status } = useSelector((state) => state.allUsers);
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const columns = useMemo(() => COLUMNS, []);
  const data = allusers?.data || [];

  if (status === "loading.....") {
    return <div><p>
      <CirclesWithBar
          type="Oval"
          color="#06a9eaec"
          height={200}
          align="center"
          width={160}
      /></p></div>;
  }

  if (status === "failed") {
    return <div>Error occurred while fetching users.</div>;
  }

  return <Table columns={columns} data={data} />;
};

export default UserTable;
