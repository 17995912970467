import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../../../../utils/apiUtilis.js";


export const getonestudent = createAsyncThunk(
    "api/v1/administration/academics/student",
    async ({id}, {rejectWithValue}) => {

            try {
                const response = await axios.get(
                    `${BASE_URL}/api/v1/student/one/student/${id}`
                );
                console.log("This is all for one student datas student response", response.data)
                return response.data;

            } catch (err) {
                console.log("This is error", err.response.data.message)
                return rejectWithValue(err.response.data.message);
            }
    }
);

// extraReducers remains the same...

const initialState = {
    student: null,
    loading: false,
    errors: null

}

const getOneStudentSlice = createSlice({
    name: "getonestudent",
    initialState,
    extraReducers: {
        [getonestudent.pending]: (state) => {
            state.loading = true;
        },
        [getonestudent.fulfilled]: (state, action) => {
            state.student = action.payload.data; // <-- Assuming the payload is a single student object
            state.loading = false;
        },
        [getonestudent.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
        },
    }
}
);

export default getOneStudentSlice.reducer;