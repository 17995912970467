import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {getallstudentinclass} from "../../Features/Redux/Administration/Academics/Student/GetAllStudentInClass.slice";
import { COLUMNS } from "../../Components/StudentTable/columns";
import Table from "../../Components/StudentTable/";
import {CirclesWithBar} from "react-loader-spinner";
import {useParams} from "react-router-dom";

const StudentTable = () => {
  const dispatch = useDispatch();
  const { data: allusers, status,loading } = useSelector((state) => state.getallstudentinclass);
  const {id}=useParams();
  useEffect(() => {
    dispatch(getallstudentinclass(id));
  }, [dispatch,id]);

  const columns = useMemo(() => COLUMNS, []);
  const data = allusers?.data || [];
  console.log ("This is all student fromm the table", allusers);

  if (loading) {
    return <div><p>
      <CirclesWithBar
          type="Oval"
          color="#06a9eaec"
          height={200}
          align="center"
          width={160}
      /></p></div>;
  }

  if (status === "failed") {
    return <div>Error occurred while fetching users.</div>;
  }

  return <Table columns={columns} data={data} />;
};

export default StudentTable;
