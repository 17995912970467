import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCourses } from '../../../../Features/Redux/Administration/Academics/Course/GetAllCourses.slice';


export default function CourseList() {
    const dispatch = useDispatch();

    const { datas, loading, errors } = useSelector(state => state. getcourses);

    useEffect(() => {
        dispatch(getCourses());
    }, [dispatch]);

    if (loading) return <p>Loading...</p>;
    if (errors) return <p>Error: {errors}</p>;
    // console.log("This is the datas", datas)

    return (
        <div>
            {/*{datas.map(course => (*/}
            {/*    <div key={course.id}>{course.name}</div>*/}
            {/*))}*/}
            {Array.isArray(datas) &&
                datas.map((course) => (
                    <div key={course.id}> {course.name}
                    </div>
                ))}
        </div>
    );

}

