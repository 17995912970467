import React from 'react'
import ParentDash from '../ParentDashboard'
import logo from "../../../assets/images/logo-bgremove.png"
import "./form.css"
import {getTuitionplan} from "../../../Features/Redux/parent/GetAllTuitionPlan";
import {useDispatch, useSelector} from "react-redux";
import {tuitionPayment} from "../../../Features/Redux/parent/ParentPayTuitionSlice";
import {mystudents} from "../../../Features/Redux/parent/ParentMyStudentSlice";
import{getallterms} from "../../../Features/Redux/Administration/Academics/TermandMidterms/Term/GetAllterms";
import {useEffect} from "react";
//import react form
import { useForm } from "react-hook-form";
import {toast} from "react-toastify";


const TuitionPayment = () => {
    const dispatch = useDispatch();
    const [termId, setTermId] = React.useState("");
    const [tuitionId, setTuitionId] = React.useState("");
    const [studentId, setStudentId] = React.useState("");
    const initialValues = {
        cardNumber: '4242424242424242',
        expMonth: '12',
        expYear: '2024',
        cvc: '155',
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(mystudents());
                const response1 = await dispatch(getTuitionplan());
                const response2 = await dispatch(getallterms());
                setStudentId(response.payload.data); // Assuming the response contains an array of category objects
                setTuitionId(response1.payload.data);
                setTermId(response2.payload.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [dispatch]);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        const payment = {
            ...data
        }
        dispatch(tuitionPayment(payment));

    }

  return (
    <div>
        <ParentDash>
      <div className='flex justify-between mt-10 relative pl-22'>
<div className='w-[40%] p-12 mt-10 absolute'> 
        <span>Tuition/Payment</span>
        <h1 className='font-bold text-[1.5rem] py-3'>Online School fees payment </h1>
        <p>Use online payment form to create easiest, user-friendly payment processes that give you more leads, customers, and sales—all with zero extra charges like ticket and time. it provide high quantities service, collect all payments for subscription services, you can use any payment method  and so much more. We integrate with some of the most trusted names in payment processing, including banks, Stripe, and PayPal. Get started by browsing through our payment forms below.</p>


        </div>
        <div className='w-[38%] absolute left-[460px]'>
          <div className='form-part absolute'></div>
          <div className='form w-[80%] bg-white my-10 p-5 rounded-md relative'>
            <img src={logo} alt="logo" className='w-10 h-10 m-auto'/>
            <h2 className='text-center'>Tuition fees payment form</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-3">
              <div>
            <select
                {...register('studentId', { required: true })}
              className="w-full p-1 mt-2 border rounded focus:outline-none focus:border-blue-500"
            >
                <option value="">Select Student</option>

                {studentId && Array.isArray(studentId) &&
                    studentId.map((student) => (
                        <option key={student.id} value={student.id}>
                            {student.name} {student.secondname}
                        </option>
                    ))}
            </select>
                    {errors.studentId && (
                         <span className="text-red-500">This field is required</span>

                    )}
                </div>

              <div>
                  <select
                      {...register('termId', { required: true })}
                      className="w-full p-1 mt-2 border rounded focus:outline-none focus:border-blue-500"
                  >
                      <option value="">Select term to be paid</option>

                      {termId && Array.isArray(termId) &&
                          termId.map((term) => (
                              <option key={term.id} value={term.id}>
                                  {term.name}
                              </option>
                          ))}
                  </select>
                  {errors.studentId && (
                      <span className="text-red-500">This field is required</span>

                  )}
              </div>
              <div>
                  <select
                      {...register('tuitionId', { required: true })}
                      className="w-full p-1 mt-2 border rounded focus:outline-none focus:border-blue-500"
                  >
                      <option value="">Select Tuition Plan</option>

                      {tuitionId && Array.isArray(tuitionId) &&
                          tuitionId.map((tuition) => (
                              <option key={tuition.id} value={tuition.id}>
                                  {tuition.title} {tuition.amount}
                              </option>
                          ))}
                  </select>
                  {errors.studentId && (
                      <span className="text-red-500">This field is required</span>

                  )}
              </div>

              <input
                  {...register('cardNumber', { required: true })}
                  className="w-full p-1 mt-2 border rounded focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="Card Number"
                  defaultValue={initialValues.cardNumber}
                  readOnly
              />
              {errors.cardNumber && (
                  <span className="text-red-500">This field is required</span>
              )}

              <input
                  {...register('expMonth', { required: true })}
                  className="w-full p-1 mt-2 border rounded focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="Expired month"
                  defaultValue={initialValues.expMonth}
                  readOnly
              />
              {errors.expMonth && (
                  <span className="text-red-500">This field is required</span>
              )}

              <input
                  {...register('expYear', { required: true })}
                  className="w-full p-1 mt-2 border rounded focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="Expired year"
                  defaultValue={initialValues.expYear}
                  readOnly
              />
              {errors.expYear && (
                  <span className="text-red-500">This field is required</span>
              )}

              <input
                  {...register('cvc', { required: true })}
                  className="w-full p-1 mt-2 border rounded focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="CVC"
                  defaultValue={initialValues.cvc}
                  readOnly
              />
              {errors.cvc && (
                  <span className="text-red-500">This field is required</span>
              )}
           
          </div>

          <button
            className="w-full p-2 mt-5 mb-5 text-white rounded-md"
            style={{ backgroundColor: "rgba(19, 61, 103, 0.9)" }}
            type="submit"
          >
            Pay
          </button>
        </form>

          </div>

        </div>
      </div>

      </ParentDash>
    </div>
  )
}

export default TuitionPayment
