import React from 'react';
import logo from '../assets/images/logo.jpeg';


const Footer = () => {
    const d = new Date();
    let year = d.getFullYear();
    return (
        <>
            <div className='footer-container'>
                <div className='footer'>
                    <div className='footer-logo'>
                        <div className="nav-logo">
                            <img src={logo} className='nav-logo--img' />
                            <h1 className='nav-logo--name'>P<span>&#9733;</span>M<span>&#9733;</span>S</h1>

                        </div>
                        <p>A full sercives studio <span>for the visionaries</span></p>
                        <p>TERMS OF SERVICE<span>PRIVACY POLICY</span></p>
                    </div>

                    <div className='footer-get-touch'>
                        <h3>GET IN TOUCH</h3>
                        <p>MON-FRIDAY<span>10AM-4PM EST</span></p>
                        <p>CONTACT <span>INSTAGRAM</span><span>PINTEREST</span></p>
                    </div>
                    <div className='footer-contact'>
                        <h3>JOIN OUR MAILING</h3>
                        <form>
                            <label htmlFor='firstName'>First Name</label>
                            <input type="text" name="firstName" id="firstName" />
                            <label htmlFor="email">EMAIL</label>
                            <input type="email" name="email" id="email" />
                            <ul className='btn-container'>
                                <li><a href='#' className='btn'>SEND</a></li>
                            </ul>
                        </form>
                    </div>
                    <div className='explore'>
                        <h3>Explore</h3>
                        <ul className='footer-links'>
                            <li><a href='/'>HOME</a></li>
                            <li><a href='#'>ABOUT</a></li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href='#'>FEATURE</a></li>
                            <li><a href='#'>SUPPORT</a></li>
                            <li><a href='#'>FAQS</a></li>
                        </ul>
                    </div>
                </div>


            </div>
            <div className='footer-copyright'>

                <p>Copyright&copy;{year}. All RIghts Reserved</p>
            </div>
        </>


    )
}
export default Footer;