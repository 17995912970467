import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Select } from "antd";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Admin from "../../../../admin/admin";

function AddMidTerm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Admin>
        <div className="p-4 w-64 ">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 focus:outline-none"
            onClick={showModal}
          >
            Add Mid Term
          </button>
          <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className="p-10"
          >
            <h1 className="text-2xl text-center">Add Mid Term</h1>
            <form className="">
              <div className="mt-10">
                <label className="pl-20 text-lg ">MidTerm name</label> <br />
                <FormControl
                  sx={{
                    marginLeft: "80px",
                    width: "260px",
                    padding: "20px 0 20px 0",
                  }}
                >
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Term Name"
                    placeholder="Term Name"
                  >
                    <MenuItem value={10}>First Term</MenuItem>
                    <MenuItem value={20}>Second Term</MenuItem>
                    <MenuItem value={30}>Third Term</MenuItem>
                  </Select>
                </FormControl>
                <br />
                <label className="pl-20 text-lg py-3">Mid Term name</label>
                <input
                  className="w-2/3 mx-20 mb-10 p-2 mt-2 border rounded focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="Enter midterm name"
                />
              </div>

              <button
                className="w-full p-2 mt-10 mb-5 text-white rounded-md"
                style={{ backgroundColor: "rgba(19, 61, 103, 0.9)" }}
              >
                Add
              </button>
            </form>
          </Modal>
        </div>
      </Admin>
    </>
  );
}

export default AddMidTerm;
