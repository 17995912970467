import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Image } from "cloudinary-react";
import PhoneInput from 'react-phone-input-2';
import { MdPhone } from 'react-icons/md';
import "react-phone-input-2/lib/style.css";
import Admin from '../../../admin/admin';
import {addTeacher} from '../../../../Features/Redux/Administration/Academics/Teachers/AddTeacher.slice'
import {CirclesWithBar} from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function AddStaff() {
    const [imageError, setImageError] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [seconds, setSeconds] = useState(5);


    const handleImageError = () => {
        setImageError(true);
    };
    let imageUrl;
    const dispatch = useDispatch();
    const { data: studentData, loading: studentLoading, Error ,rejected,isFulfilled } = useSelector(
        (state) => state.addteacher
    );
    const navigate = useNavigate();
    // const [image, setImage] = useState('');
    const [image, setImage] = useState('')

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'gwkladqc');

        // Upload image to Cloudinary
        fetch('https://api.cloudinary.com/v1_1/dgcmsqndb/image/upload', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {

                imageUrl = `https://res.cloudinary.com/dgcmsqndb/image/upload/${data.public_id}`;
                setImage(imageUrl);
                console.log('Image URL:', imageUrl);

            })

            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };
    const handleSnackbarClose = () => {
        setShowSnackbar(false);
    };


    const onSubmit = (formData) => {
        const newTeacherData = {
            ...formData,
            image
        };
        // newStudentData
        // Dispatch the addStudent action with the newStudentData
        dispatch(addTeacher(newTeacherData))
            .then((resp) => {
                // Reset the form
                // toast.success("New product added!");
            })
            .catch((err) => {
                console.log("Login error:", Error);
                setShowError(true);
                setShowSnackbar(true);
                console.log("Front error",err);
            });

        // After successful registration, you can navigate to a different page
        // navigate('/listusers'); // Replace '/success' with the path of the success page
    };
    console.log("Error", Error);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    // if (isFulfilled) {
    //     // navigate("/listusers")
    //     return
    // }
    useEffect(() => {
        if (isFulfilled) {
            if (seconds > 0) {
                const timer = setTimeout(() => {
                    setSeconds(seconds - 1);
                }, 1000);

                return () => clearTimeout(timer);
            } else {
                // Navigate to "/listusers"
                window.location.reload();
                console.log("Navigating...");
            }
        }
    }, [isFulfilled, seconds]);



    return (
        <>

            <Admin>
                {!isFulfilled && (
                <div className="min-h-screen flex items-center justify-center mg-3rem">
                    <div className="bg-white p-8 shadow-md rounded-md max-w-screen-md w-full ">
                        {/*//max-w-screen-lg*/}
                        <div className="flex justify-center">
                            <h1 className="text-3xl font-semibold mb-6">Register Staff Member</h1>
                        </div>
                        <div className="flex justify-center">
                            <img src={image} alt="Staff Picture" className="w-32 h-32 rounded-full object-cover mb-4" />
                        </div>
                        <div className="flex justify-center">
                            {errors && <span className="text-red-500">{Error}</span>}
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap">
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="name" className="block mb-1 font-medium">
                                    Full Name:
                                </label>
                                <input
                                    {...register('fullname', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.fullname && <span className="text-red-500">This field is required</span>}
                            </div>


                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentemail" className="block mb-1 font-medium">
                                    Email:
                                </label>
                                <input
                                    {...register('email', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.email && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="gender" className="block mb-1 font-medium">
                                    Gender:
                                </label>
                                <select
                                    {...register('gender', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                >
                                    <option value="">Select gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                {errors.gender && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="gender" className="block mb-1 font-medium">
                                    Staff Category
                                </label>
                                <select
                                    {...register('roleId', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                >
                                    <option value="">Select Category</option>
                                    <option value="1">Administration</option>
                                    <option value="2">Teacher</option>
                                    <option value="3">Guest</option>
                                </select>
                                {errors.roleId && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="dateofbirth" className="block mb-1 font-medium">
                                    Date of Birth:
                                </label>
                                <input
                                    type="date"
                                    {...register('birthdate', { required: true })}
                                    // max={new Date().toISOString().split('T')[0]}  // Set max to today
                                    max={(new Date().getFullYear() - 18) + '-01-01'}

                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"


                                />
                                {errors.birthdate && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentname" className="block mb-1 font-medium">
                                    Password:
                                </label>
                                {/*<input*/}
                                {/*    {...register('password', { required: true })}*/}
                                {/*    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"*/}
                                {/*/>*/}
                                <div className="relative">
                                    <input
                                        {...register('password', { required: true })}
                                        type={showPassword ? 'text' : 'password'}
                                        className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                        placeholder="Type Password"
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                                    >
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                {errors.password && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentsecondname" className="block mb-1 font-medium">
                                    Confirm Password:
                                </label>
                                <div className="relative">
                                    <input
                                        {...register('confirmPassword', { required: true })}
                                        type={showPassword ? 'text' : 'password'}
                                        className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                        placeholder="Confirm Password"
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                                    >
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>

                                {errors.confirmPassword && <span className="text-red-500">This field is required</span>}
                            </div>


                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentphone" className="block mb-1 font-medium">
                                    Contact Phone:
                                </label>
                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{ required: 'This field is required' }}
                                    render={({ field }) => (
                                        <div className="relative">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <MdPhone className="text-lg text-gray-500" />
                                            </span>
                                            <PhoneInput
                                                {...field}
                                                defaultCountry="RW"
                                                inputProps={{
                                                    className: `w-full pl-10 border rounded-md py-2 px-3 outline-none ${errors.parentphone ? 'ring-red-500' : 'focus:ring-[#133d67]'
                                                    }`,
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                {errors.phone && <span className="text-red-500">{errors.phone.message}</span>}
                            </div>


                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentlocation" className="block mb-1 font-medium">
                                    Staff Address:
                                </label>
                                <input
                                    {...register('address', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.address && (
                                    <span className="text-red-500">This field is required</span>
                                )}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="image" className="block mb-1 font-medium">
                                    Image:
                                </label>
                                <input
                                    type="file"
                                    {...register('image', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                    multiple
                                    onChange={handleImageUpload} // Call the handleImageUpload function when the file is selected
                                />
                                {errors.image && <span className="text-red-500">This field is required</span>}
                                {imageUrl && ( // Display the image only if imageUrl is not empty
                                    <div className="mt-2">
                                        <Image
                                            cloudName="your_cloudinary_cloud_name"
                                            publicId={imageUrl}
                                            width="150"
                                            height="150"
                                            crop="fill"
                                        />
                                    </div>
                                )}

                            </div>
                            <div className="flex justify-between items-center">
                                {/*<button*/}
                                {/*    type="submit"*/}
                                {/*    className="bg-[#133d67] hover:bg-[#06a9eaec] text-white py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"*/}
                                {/*>*/}
                                {/*    Register*/}
                                {/*</button>*/}
                                <div className='btn-container'>
                                    <button
                                        type="submit"
                                        className="btn btn-sign-in"
                                    >
                                        {studentLoading ? ( // Use local isLoading state for spinner
                                            <CirclesWithBar
                                                type="Oval"
                                                color="#fff"
                                                height={45}
                                                width={45}
                                            />
                                        ) : (
                                            "Register"
                                        )}
                                    </button>
                                    {/*{showError && <div className="error">{Error}</div>}*/}
                                </div>
                                <Link
                                    to="/listusers"
                                    className="text-blue-500 hover:text-blue-600 transition duration-300 ease-in-out ml-4"
                                >
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    </div>
                </div> )}
                {isFulfilled && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: '#f0f0f0'
                    }}>
                        <div style={{
                            padding: '20px',
                            backgroundColor: '#ffffff',
                            borderRadius: '8px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center'
                        }}>
                            <p style={{
                                fontSize: '18px',
                                margin: '0',
                                color: '#333333'
                            }}>
                                Check your email to proceed with verification
                            </p>
                            {seconds > 0 && <p>Redirecting in {seconds} seconds...</p>}
                        </div>
                    </div>
                )}
            </Admin>

        </>
    );
}
