import React from "react";

function NotFound() {
    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-4xl font-bold mb-4">Page Not Found</h1>
            <p className="text-gray-600 text-lg mb-8">
                The page you are looking for doesn't exist.
            </p>
            <button
                onClick={goBack}
                className="text-blue-500 hover:underline cursor-pointer"
            >
                Go back
            </button>
        </div>
    );
}

export default NotFound;
