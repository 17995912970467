import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { addClass } from '../../../../Features/Redux/Administration/Academics/Class/Addclass.slice';
import ClassesList from './GetClasses';
import {CirclesWithBar} from "react-loader-spinner";
import Admin from "../../../admin/admin";

import { Modal } from 'antd';
function AddClass() {
    const dispatch = useDispatch();
    const [className, setClassName] = useState('');
    const [error, setError] = useState(null);
    const { status, errors, loading } = useSelector((state) => state.addclass);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation
        if (!className) {
            setError('Class name is required');
            return;
        }

        dispatch(addClass({ name: className }));
        setClassName('');
        setError(null);
    };
    if (errors) {
        return <p>Error: {errors}</p>;
    }
  
    return (
        <Admin>
        <div className="p-4 w-64">
            {/* <form className="flex space-x-2 items-center" onSubmit={handleSubmit}>
                <input
                    className="flex-grow p-2 border rounded focus:outline-none focus:border-blue-500"
                    type="text"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                    placeholder="Enter class name"
                />
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 focus:outline-none"
                    type="submit"
                >
                    Add
                </button>
            </form>
            {error && <p className="text-red-500 mt-2">{error}</p>} */}
            <div className="p-4 w-64 ">
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 focus:outline-none"
        onClick={showModal}
      >
        Add Class
      </button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="p-10"
      >
        <h1 className="text-2xl text-center">Add Class</h1>
        <form onSubmit={handleSubmit}>
          <div className="mt-10">
            <label className="pl-20 text-lg ">Class name</label>
            <input
              className="w-2/3 mx-20 mb-10 p-2 mt-2 border rounded focus:outline-none focus:border-blue-500"
              type="text"
              
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              placeholder="Enter class name"
            />
          </div>

          <button
            className="w-full p-2 mt-10 mb-5 text-white rounded-md"
            style={{ backgroundColor: "rgba(19, 61, 103, 0.9)" }}
            type="submit"
          >
            Add
          </button>
        </form>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </Modal>
    </div>
            <ClassesList />
            
        </div>
            <ClassesList />
        </Admin>
    );
}

export default AddClass;
