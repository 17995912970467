import React,{useState} from 'react'
import { useForm, Controller } from 'react-hook-form';
import "react-phone-input-2/lib/style.css";
import Admin from '../../../admin/admin';
import { Button, Modal } from "antd";
import { toast } from 'react-toastify';
import{getallstudentinclass} from "../../../../Features/Redux/Administration/Academics/Student/GetAllStudentInClass.slice";
import {getClasses} from "../../../../Features/Redux/Administration/Academics/Class/GetClasses.slice";
import {getCourses} from "../../../../Features/Redux/Administration/Academics/Course/GetAllCourses.slice";
import {addmarks} from "../../../../Features/Redux/Administration/Marks/AddMarkSlice.slice";
import {getAllMidterms} from "../../../../Features/Redux/Administration/Academics/TermandMidterms/Midterm/GetAllMidterms.slice";
import {getallterms} from "../../../../Features/Redux/Administration/Academics/TermandMidterms/Term/GetAllterms";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";


const DisplayMarks = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [classesIds, setClassesIds] = useState("");
    const [courseIds , setCourseIds] = useState("");
    const [termIds , setTermIds] = useState("");
    const [midtermIds , setMidtermIds] = useState("");
    const [students, setStudents] = useState([]);
    const dispatch = useDispatch();
    const [selectedClassId, setSelectedClassId] = useState("");
    const {status,isfulfilled, errors: Error} = useSelector((state) => state.addmarks);


    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(getClasses());
                const response1 = await dispatch(getCourses());
                const response2 = await dispatch(getallterms());
                const response3 = await dispatch(getAllMidterms());
                setClassesIds(response.payload.data); // Assuming the response contains an array of category objects
                setCourseIds(response1.payload);
                setTermIds(response2.payload.data);
                setMidtermIds(response3.payload);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [dispatch]);
   const RetrieveStudents = async (e) => {
        try {
            const response = await dispatch(getallstudentinclass(selectedClassId));
            setStudents(response.payload.data);
        } catch (error) {
            console.log(error);
        }
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
const onSubmit = (data) => {
    const marks = {
        ...data
    }
    dispatch(addmarks(marks));

}


  return (
    <div>
      
      <button type="submit" className="bg-[#133d67] hover:bg-[#06a9eaec] text-white py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"
              onClick={showModal}
      >
        Add</button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        width={800}
        onCancel={handleCancel}
        footer={null}
        className="p-10"
      >

<div className="bg-white p-8  w-full ">
                        <div className="flex justify-center">
                            <h1 className="text-3xl font-semibold mb-6">Add Marks</h1>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-wrap">
                

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="gender" className="block mb-1 font-medium">
                                    Class
                                </label>
                                <select
                                    {...register('Studentclass', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                    onChange={(e) => setSelectedClassId(e.target.value)}
                                >

                                    <option value="">Select Student Class</option>
                                    {Array.isArray(classesIds) &&
                                        classesIds.map((classes) => (
                                            <option key={classes.id} value={classes.id}>
                                                {classes.name}
                                            </option>
                                        ))}
                                </select>
                                {errors.Studentclass && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">

                                <label htmlFor="gender" className="block mb-1 font-medium">
                                   Student
                                </label>
                                <select
                                    {...register('studentId', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                    onClick={RetrieveStudents}
                                >
                                    <option value="">Select Student</option>

                                    {students && Array.isArray(students) &&
                                        students.map((student) => (
                                            <option key={student.id} value={student.id}>
                                                {student.name} {student.secondname}
                                            </option>
                                        ))}
                                </select>
                                {errors.studentId && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="gender" className="block mb-1 font-medium">
                                    Term
                                </label>
                                <select
                                    {...register('termId', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                >
                                    <option value="">Select Academic term</option>
                                    {Array.isArray(termIds) &&
                                        termIds.map((term) => (
                                            <option key={term.id} value={term.id}>
                                                {term.name}
                                            </option>
                                        ))}
                                </select>
                                {errors.termId && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="gender" className="block mb-1 font-medium">
                                   MidTerm
                                </label>
                                <select
                                    {...register('midtermId', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                >
                                    <option value="">Select Academic Mid-term</option>
                                    {Array.isArray(midtermIds) &&
                                        midtermIds.map((midterterm) => (
                                            <option key={midterterm.id} value={midterterm.id}>
                                                {midterterm.name}
                                            </option>
                                        ))}
                                </select>
                                {errors.midtermId && <span className="text-red-500">This field is required</span>}
                            </div>
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <label htmlFor="gender" className="block mb-1 font-medium">
                                        Course
                                    </label>
                                    <select
                                        {...register('courseId', { required: true })}
                                        className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                    >
                                        <option value="">Select Course</option>
                                        {Array.isArray(courseIds) &&
                                            courseIds.map((course) => (
                                                <option key={course.id} value={course.id}>
                                                    {course.name}
                                                </option>
                                            ))}

                                    </select>
                                    {errors.courseId && <span className="text-red-500">This field is required</span>}
                                </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="cat1" className="block mb-1 font-medium">
                                    Quiz 1
                                </label>
                                <input
                                    type='number'
                                    {...register('cat1', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.cat1 && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="dateofbirth" className="block mb-1 font-medium">
                                    Quiz 2
                                </label>
                                <input
                                    type='number'
                                    {...register('cat2', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.cat2 && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="exam" className="block mb-1 font-medium">
                                    Exam
                                </label>
                                <input
                                    type='number'
                                    {...register('exam', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.exam && <span className="text-red-500">This field is required</span>}
                            </div>

                           
                            </div>
                            <div className="ml-60 mt-2">
                                <button
                                    type="submit"
                                    className="bg-[#133d67] hover:bg-[#06a9eaec] text-white py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
      </Modal>
    </div>
  )
}

export default DisplayMarks
