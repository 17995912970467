
import React, { useState, useRef } from "react";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verify } from "../../Features/Redux/User/loginSlice";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useSpring, animated } from 'react-spring';

const VerifyOtp = () => {
    const navigate = useNavigate();
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const [isVerified, setIsVerified] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const dispatch = useDispatch();
    const email = Cookies.get("email");
    const { status, Error } = useSelector((state) => state.user);

    const handleSubmit = (event) => {
        event.preventDefault();
        const otpString = otp.join('');
        dispatch(verify({ otp: otpString, email }))
            .then((response) => {
                console.log("Login response:", response)
                if (response && response.payload.message === 'Login successful') {
                    setIsVerified(true);
                    navigate('/dashboard');
                } else {
                    setIsVerified(false);
                }
            })
            .catch((error) => console.log("Login error:", error));
    };

    const formAnimation = useSpring({
        from: { opacity: 0, transform: "translateY(20px)" },
        to: { opacity: 1, transform: "translateY(0px)" },
    });

    const inputRefs = useRef([]);

    const focusNextInput = (index) => {
        if (index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleInputChange = (index, value) => {
        setOTP((prevOTP) => {
            const updatedOTP = [...prevOTP];
            updatedOTP[index] = value;
            return updatedOTP;
        });

        if (value && (index < inputRefs.current.length - 1)) {
            focusNextInput(index);
        }
    };
    // Animation config for the form
    // const formAnimation = useSpring({
    //     from: { opacity: 0, transform: "translateY(20px)" },
    //     to: { opacity: 1, transform: "translateY(0px)" },
    // });

    // Countdown timer for resending OTP
    const handleResendOTP = () => {
        // Simulate the API call to resend OTP
        // For demonstration purposes, we're using a setTimeout
        // In a real application, you'd send a request to your backend to resend the OTP
        setResendTimer(30); // Reset the timer
        const timerId = setInterval(() => {
            setResendTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timerId);
        }, 30000);
    };
    const maskEmail = (email) => {
        if (email){
            const [name, domain] = email.split("@");
            const maskedName = name.slice(0, 3) + "*".repeat(name.length - 3);
            return maskedName + "@" + domain;
        }
        else {
            return
        }

    };

    return (
        <div className="verifyOtp-container flex items-center justify-center bg-gray-100">
            <animated.div style={{ ...formAnimation, height: "550px" }} className="verify bg-white p-8 rounded-lg shadow-md">
                {/*add title as Verify otp*/}
                <div className="verify-title flex items-center justify-center">
                    <h2 className="text-2xl font-bold"></h2>
                </div>
                <div className="verify-account">
                    <h3 className="text-xl font-bold mb-2">Account <span className="font-normal">Verification</span></h3>
                    <p>We have sent a code to your email <span className="font-medium">{maskEmail(email)}</span></p>
                </div>
                <form onSubmit={handleSubmit} className="form mt-6">
                    <div className="form-header flex items-center mb-4">
                        <LockOpenIcon className="w-6 h-6 mr-2" />
                        <h3 className="text-lg font-bold">Enter your code</h3>
                    </div>
                    <div className="otp-input-container flex justify-center items-center">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                className="otp-input"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                ref={(el) => (inputRefs.current[index] = el)}
                                style={{ marginRight: "0.5rem" }}
                            />
                        ))}

                    </div>

                    <div className='btn-container mt-8'>
                        <button type="submit" className='btn btn-secondary py-2 px-4 rounded-md'>Verify</button>
                    </div>
                    {Error && (
                        <div className="resend-otp mt-6 text-center">
                            {resendTimer > 0 ? (
                                <p className="text-red-500 font-bold">{Error}</p>
                            ) : null}
                        </div>
                    )}
                </form>

            </animated.div>
            {/*{Error && (*/}
            {/*    <div className="resend-otp mt-6 text-center">*/}
            {/*        {resendTimer > 0 ? (*/}
            {/*            <p className="">{Error}</p>*/}
            {/*        ) : null}*/}
            {/*    </div>*/}
            {/*)}*/}

        </div>

    );
}

export default VerifyOtp;


