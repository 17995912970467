import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import loginReducer from "../../Features/Redux/User/loginSlice";
import parentsLoginSlice from "../../Features/Redux/parent/parentLoginSlice";
import addClassSlice from "../../Features/Redux/Administration/Academics/Class/Addclass.slice";
import getClassesSlice from "../../Features/Redux/Administration/Academics/Class/GetClasses.slice";
import addCourseSlice from "../../Features/Redux/Administration/Academics/Course/Addcourse.slice";
import getCoursesSlice from "../../Features/Redux/Administration/Academics/Course/GetAllCourses.slice";
import addStudentSlice from "../../Features/Redux/Administration/Academics/Student/AddStudent.slice";
import getAllStudentSlice from "../Redux/Administration/Academics/Student/GetAllStudent.slice";
import addTeacherSlice from "../Redux/Administration/Academics/Teachers/AddTeacher.slice";
import { singleUserReducer } from "../Redux/User/getOneUserSlice";
import AlluserSlice from "../Redux/User/viewUser/view.slice";
import setRoleSlice from "../Redux/User/viewUser/setRole.slice";
import setAccountStatusReducer from "../Redux/User/accountStatusSlice";
import getOneStudentSlice from "../Redux/Administration/Academics/Student/GetOneStudent.slice";
import getAllStudentInClassSlice from "../Redux/Administration/Academics/Student/GetAllStudentInClass.slice";
import addmidtermSlice from "../Redux/Administration/Academics/TermandMidterms/Midterm/Addmidterm.slice";
import resetPasswordReducer from "../../Features/passwordResetSlice";
import addmarksSlice from "../Redux/Administration/Marks/AddMarkSlice.slice";
import allParentwithStudentSlice from "../Redux/parent/allParentwithStudentSlice";
import getAllPaymentSlice from "../Redux/Administration/Academics/Tuition/GetAllTuition.slice";
import getAllContentSlice from "../Redux/Administration/Academics/Student/dashContent";
import getAllMidtermsSlice from "../Redux/Administration/Academics/TermandMidterms/Midterm/GetAllMidterms.slice";
import getTermsSlice from "../Redux/Administration/Academics/TermandMidterms/Term/GetAllterms";
import parentMystudentSlice from "../Redux/parent/ParentMyStudentSlice";
import ParentPayTuitionSlice from "../Redux/parent/ParentPayTuitionSlice";
import getallTuitionSlice from "../Redux/Administration/Academics/Tuition/GetAllTuition.slice";

// import addTermSlice from "../Redux/Administration/Academics/Term and Midterms/Term/Addterm.slice";

const middlewares = [];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: {
    resetPassword: resetPasswordReducer,
    user: loginReducer,
    parent: parentsLoginSlice,
    addclass: addClassSlice,
    getclasses: getClassesSlice,
    addcourse: addCourseSlice,
    getcourses: getCoursesSlice,
    addstudent: addStudentSlice,
    getallstudent: getAllStudentSlice,
    addteacher: addTeacherSlice,
    allUsers: AlluserSlice,
    setRole: setRoleSlice,
    setAccount: setAccountStatusReducer,
    oneuser: singleUserReducer,
    getonestudent: getOneStudentSlice,
    getallstudentinclass: getAllStudentInClassSlice,
    addmidterm: addmidtermSlice,
    addmarks: addmarksSlice,
    parentstudents: allParentwithStudentSlice,
    getallpayment: getAllPaymentSlice,
    dashboard: getAllContentSlice,
    getallmidterm: getAllMidtermsSlice,
    getallterm: getTermsSlice,
    allcourses: getCoursesSlice,
    allclasses: getClassesSlice,
    mystudents: parentMystudentSlice,
    tuitionPayment:ParentPayTuitionSlice,
    getTuitionplan:getallTuitionSlice,
  },
  middleware: [...middlewares, thunk],
});

export default store;
