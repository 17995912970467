import SideBar from "../../Components/SideBar/SideBar";
import Content from "../../Components/Content/Content";
import NavBar from "../../Components/Dashboard/Header";
import StudentTable from "./StudentTable";
import ClassesList from "../../Components/Administration/Academics/Class/GetClasses";
import "../../styles/interface.scss";
import Admin from "../../Components/admin/admin";
import StudentMarkTable from "./StudentTable";
import ClassesListForMarks from "../../Components/Administration/Academics/Class/GetClassesforMarks";
import DisplayMarks from "../../Components/Administration/Academics/marks/DisplayMarks";

function ListStudentMarks() {
  return (
    <>



      <Admin>
       <DisplayMarks/>
        <ClassesListForMarks />
        <p className="text-lg font-semibold">List of Students Marks</p>
        <div className="bg-[#133d67] flex justify-center items-center">
        <p className="text-lg text-white">
          Manage all All Students Marks
        </p>
        </div>
        <StudentMarkTable />
      </Admin>


    </>
  );
}

export default ListStudentMarks;
