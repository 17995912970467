import SideBar from "../../Components/SideBar/SideBar";
import Content from "../../Components/Content/Content";
import NavBar from "../../Components/Dashboard/Header";
import StudentTable from "./StudentTable";
import ClassesList from "../../Components/Administration/Academics/Class/GetClasses";
import "../../styles/interface.scss";
import Admin from "../../Components/admin/admin";
import StudentTableInClass from "./StudentTable";
import DisplayMarks from "../../Components/Administration/Academics/marks/DisplayMarks";

function ListStudentTableInClass() {
  return (
    <>

      <Admin>
          <DisplayMarks/>
          <p className="title">List of Students</p>
          <p className="sub-title">
            Manage all All Students
          </p>
          <StudentTableInClass />

      </Admin>
    </>
  );
}

export default ListStudentTableInClass;
