import React, { useState } from 'react';
import logo from '../assets/images/logo.jpeg';
import { Link } from 'react-router-dom';


const Header = () => {
    const [open, setOpen] = useState(false);
    const [nav, setNav] = useState(false);
    const [openLinks, setOpenLinks] = useState(false)


    const changeBackground = () => {
        if (window.scrollY >= 100) {
            setNav(true)
        } else {
            setNav(false)
        }
    }
    window.addEventListener('scroll', changeBackground)
    return (
        <>
            <nav className='nav active'>
                <div className="nav-logo">
                    <img src={logo} className='nav-logo--img' />
                    <h1 className='nav-logo--name'>P<span>&#9733;</span>M<span>&#9733;</span>S</h1>
                </div>
                <ul className={openLinks ? 'nav-links active' : 'nav-links'} onClick={() => setOpenLinks(false)}>
                    <li><a href='/' className='nav-link'>Home</a></li>
                    <li><a href='#' className='nav-link'>About</a></li>
                    <li><a href='#' className='nav-link'>Contact-Us</a></li>
                    <li><a href='#' className='nav-link'>Services</a></li>
                    <li><a href='#' className='nav-link'>Features</a></li>
                    <li><a href='#' className='nav-link'>Live Demo</a></li>
                    <li><a href='#' className='nav-link'>Support</a></li>
                    <li><a href='#' className='nav-link'>Docs</a></li>
                    <li><a href='#' className='nav-link'>FAQs</a></li>

                </ul>
                <ul className={openLinks ? 'nav-links active' : 'nav-links'} onClick={() => setOpenLinks(false)}>
                    <li><a href='/staff/login' className='btn btn-transparent'>Login</a></li>
                </ul>
                <div className='menu-container' onClick={() => setOpenLinks(!openLinks)}>
                    <i className={openLinks ? "fa-solid fa-close" : "fa-solid fa-bars"} style={{ color: "#333" }}></i>

                </div>
            </nav>
        </>

    )
}
export default Header