import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../../utils/apiUtilis.js";

export const getTuitionplan = createAsyncThunk(
    "api/v1/administration/academics/class",
    async (classData, {rejectWithValue}) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/v1/tuition/get/all/tuition/plans`,
                classData
            );
            console.log("This is response", response.data)
            return response.data;

        } catch (err) {
            console.log("This is error", err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null
} ;

const getallTuitionSlice = createSlice({
    name: "getTuitionplan",
    initialState,
    extraReducers: {
        [getTuitionplan.pending]: (state) => {
            state.loading = true;

        },
        [getTuitionplan.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
        },
        [getTuitionplan.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
        }
    }
} );

export default getallTuitionSlice.reducer;