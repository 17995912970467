// slice for Adding Student class
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../../../utils/apiUtilis.js";
export const addClass = createAsyncThunk(
    "api/v1/administration/academics/class",
    async (classData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${BASE_URL}/api/v1/class/add/class`,
                classData
            );
            console.log("This is response",response.data)
            return response.data;

        } catch (err) {
            console.log("This is error",err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null
};

const addClassSlice = createSlice({
    name: "addclass",
    initialState,
    extraReducers: {
        [addClass.pending]: (state) => {
            state.loading = true;
        },
        [addClass.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
        },
        [addClass.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
        }
    }
});

export default addClassSlice.reducer;