import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../../../../utils/apiUtilis.js";


export const addTeacher = createAsyncThunk(
    "api/v1/administration/academics/teacher",
    async (teacherData, {rejectWithValue}) => {
        try {
            const response = await axios.post(
                `${BASE_URL}/api/v1/user/signup`,
                teacherData
            );
            console.log("This is response", response.data)
            return response.data;

        } catch (err) {
            console.log("This is error", err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    Error: null,
    rejected: false,
    isFulfilled: false,
}

const addTeacherSlice = createSlice({
    name: "addteacher",
    initialState,

    extraReducers: {
        [addTeacher.pending]: (state) => {
            state.loading = true;

        },
        [addTeacher.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
            state.isFulfilled = true;
            state.Error=null;
            state.rejected=false;

        },
        [addTeacher.rejected]: (state, action) => {
            state.Error = action.payload;
            state.loading = false;
            state.rejected = true;

        },

    }
}
);

export default addTeacherSlice.reducer;