import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EditModal from "../editComponent/EditModal"; // Import your modal component
import EditAccountStatus from "../editComponent/EditAccountStatus";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCog } from '@fortawesome/free-solid-svg-icons';
import EditStudent from "../editComponent/EditStudent";


const CircularImage = ({ imageUrl }) => {
  const imageStyle = {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    overflow: "hidden",
    margin: "0 auto",
  };

  const imgStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
      <div style={imageStyle}>
        <img src={imageUrl} alt="SID" style={imgStyle} />
      </div>
  );
};
export const COLUMNS = [
  {
    Header: "No.",
    accessor: "id",
    Cell: ({ row }) => row.index + 1, // Custom accessor to display count from 1
  },
  {
    Header:"Parent name",
    accessor:"name",
  },
  {
    Header: "Parent S.name",
    accessor: "secondname",
  },
  {
    Header: "Address",
    accessor: "parentlocation",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Student(s)",
  //   map the student array to a string of student names
    accessor: "Students",
    // Cell: ({ row }) => {
    //     const { Students } = row.original;
    //     // const studentNames = Students.flatMap((student) => student.name);
    //     //
    //     // //return names separeted by new line
    //     // return <div>{studentNames.join("\n")}</div>;
    //   // Create an array of student names
    //   // const studentNames = Students.map((student) => `${student.name}   ${student.secondname}    ${student.Class.name}`);
    //   //
    //   // // Join the student names with a new line
    //   // const namesSeparatedByNewLine = studentNames.join("\n");
    //   //
    //   // // Return the names separated by a new line within a div
    //   // return <div>{namesSeparatedByNewLine}</
    //
    // }
    Cell: ({ row }) => {
      const { Students } = row.original;

      // Map the students to <div> elements, each containing a student name
      const studentElements = Students.map((student, index) => (
          <div key={index}>{`${student.name} ${student.secondname} ${student.Class.name}`}</div>
      ));

      // Return the array of <div> elements
      return studentElements;
    }

  },
  {
    Header: "status",
    accessor: "accountStatus",
  },

  {
    Header: "Action",
    Cell: ({ row }) => {
      const [showModal, setShowModal] = useState(false);
      const [showAccountModal, setShowAccountModal] = useState(false);
      const navigate = useNavigate();
      const { parentemail, roleId, id } = row.original;

      const openModal = (id) => {
        setShowModal(true);
      };
      const openAccountStatusModal = (id) => {
        navigate(`/all/student/schools/${id}`);
        setShowAccountModal(true);
      };

      const closeModal = () => {
        setShowModal(false);
        setShowAccountModal(false);
        navigate(`/all/student/schools`);
      };

      return (
          <div className="action">
            <FontAwesomeIcon
                icon={faEdit}
                className="text-[#06abea] cursor-pointer"
                onClick={() => openModal(id)}
            />
            <FontAwesomeIcon
                icon={faCog}
                className="text-green-500 cursor-pointer"
                onClick={() => openAccountStatusModal(id)}
            />
            {showModal && (
                <EditModal
                    email={parentemail}
                    roleId={roleId}
                    id={id}
                    closeModal={closeModal}
                />
            )}
            {showAccountModal && (
                <EditStudent id={id} closeModal={closeModal} />
            )}
          </div>
      );
    },
  },
];

