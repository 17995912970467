import SideBar from "../../Components/SideBar/SideBar";
import Content from "../../Components/Content/Content";
import NavBar from "../../Components/Dashboard/Header";
import StudentTable from "./StudentTablewithParent";
import ClassesList from "../../Components/Administration/Academics/Class/GetClasses";
import "../../styles/interface.scss";
import Admin from "../../Components/admin/admin";
import StudentWithParentTable from "./StudentTablewithParent";
import ClassesListForMarks from "../../Components/Administration/Academics/Class/GetClassesforMarks";

function ListStudentWithParent() {
  return (
    <>



      <Admin>
        {/*<ClassesListForMarks />*/}
        <p className="text-lg font-semibold">List of Parents with Students</p>
        <div className="bg-[#133d67] flex justify-center items-center">
        <p className="text-lg text-white">
          Manage all Parents with Students
        </p>
        </div>
        <StudentWithParentTable />
      </Admin>


    </>
  );
}

export default ListStudentWithParent;
