import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../../../../../utils/apiUtilis";

export const getallterms = createAsyncThunk(
    "api/v1/administration/academics/course",
    async (courseData, {rejectWithValue}) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/v1/termandmidterm/terms`,
                courseData
            );
            console.log("This is response", response.data)
            return response.data;

        } catch (err) {
            console.log("This is error", err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);

// extraReducers remains the same...

const initialState = {
    datas: [],
    loading: false,
    errors: null
} ;

const getTermsSlice = createSlice({
    name: "getallterms",
    initialState,
    extraReducers: {
        [getallterms.pending]: (state) => {
            state.loading = true;

        },
        [getallterms.fulfilled]: (state, action) => {
            state.datas = action.payload;
            state.loading = false;
        },
        [getallterms.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
        }
    }
} );

export default getTermsSlice.reducer;