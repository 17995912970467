import {useDispatch,useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Modal,message} from "antd";
import {getonestudent} from "../../Features/Redux/Administration/Academics/Student/GetOneStudent.slice";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import EditModal from "./EditModal";
import studentImg from "../../assets/images/Ellipse 5.png"
import Admin from '../admin/admin'
const EditStudent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const [name, setName] = useState("");
    const [secondName, setSecondName] = useState("");
    const [parentName, setParentName] = useState("");
    const [parentPhone, setParentPhone] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [parentAddress, setParentAddress] = useState("");
    const [studentImage, setStudentImage] = useState("");
    const [parentImage, setParentImage] = useState("");
    const [studentId, setStudentId] = useState("");
    const [gender, setGender] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [classId, setClassId] = useState("");
    const [accountstatus, setAccountstatus] = useState("");
    const [deliberation, setDeliberation] = useState("");
    const [midterageragemmarks, setMidterageragemmarks] = useState("");
    const [midtermaveragegrade, setMidtermaveragegrade] = useState("");
    const [midtermpositioninclass, setMidtermpositioninclass] = useState("");
    const [termaveragemarks, setTermaveragemarks] = useState("");
    const [termaveragegrade, setTermaveragegrade] = useState("");
    const [termpositioninclass, setTermpositioninclass] = useState("");
    const [endyearaveragemarks, setEndyearaveragemarks] = useState("");
    const [endyearaveragegrade, setEndyearaveragegrade] = useState("");
    const [endyearpositioninclass, setEndyearpositioninclass] = useState("not yet");
    const [isEditing, setIsEditing] = useState(false);



    // const {data: student,loading ,error} = useSelector((state) => state.getonestudent);
    const { loading, error } = useSelector(state => state. getonestudent);
    const params = useParams();
    // id= params;
    const dispatch = useDispatch();
    const { id } = params;
    const {register,handleSubmit,reset} = useForm();

    useEffect(() => {
        const fetchStudentDetails = async () => {
            try {
                const response = await dispatch(getonestudent({ id }));
                const formattedDateofBirth = response.payload.data.birthdate.substring(
                    0,
                    10,
                );
                setName(response.payload.data.name);
                setSecondName(response.payload.data.secondname);
                setParentName(response.payload.data.Parent.name + " " + response.payload.data.Parent.secondname);
                setParentPhone(response.payload.data.Parent.phone);
                setParentEmail(response.payload.data.parentemail);
                setParentAddress(response.payload.data.Parent.parentlocation);
                setStudentImage(response.payload.data.image);
                setParentImage(response.payload.data.parentImage);
                setStudentId(response.payload.data.SID);
                setGender(response.payload.data.gender);
                setDateOfBirth(formattedDateofBirth);
                setClassId(response.payload.data.Class.name);
                setAccountstatus(response.payload.data.accountStatus);
                setDeliberation(response.payload.data.deliberation);
                setMidterageragemmarks(response.payload.data.averageMarks);
                setMidtermaveragegrade(response.payload.data.averageGrade);
                setMidtermpositioninclass(response.payload.data.currentmidtermposition);
                setTermaveragemarks(response.payload.data.currenttermaverage);
                setTermaveragegrade(response.payload.data.currenttermgrade);
                setTermpositioninclass(response.payload.data.currenttermposition);
                setEndyearaveragemarks(response.payload.data.annualtotalAverage);
                setEndyearaveragegrade(response.payload.data.annualtotalGrade);
                setEndyearpositioninclass(response.payload.data.position);

                console.log("this is the for one student response", response.payload.data);
            } catch (error) {
                return error;
            }
        };
        fetchStudentDetails();
    }, [dispatch, id]);
    if (loading) {
        return <div>Loading...</div>
    }
    if (error) {
        return <div>Error occurred while fetching student.</div>
    }
    // const studentDetails = useSelector((state) => state.getonestudent);

    return (
        <>
            {/* <div className="bg-white rounded-lg shadow p-6 mx-auto w-96">
                <div className="text-center mb-4">
                    <div className="w-32 h-32 rounded-full mx-auto bg-gray-400" style={{ backgroundImage: `url(${studentImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                    <h2 className="text-xl font-semibold">Student Details</h2>
                </div>
                {loading && <div>Loading...</div>}
                {error && <div>Error occurred while fetching student.</div>}
                {!loading && !error && (
                    <>
                        <div>
                            <span className="font-semibold">Name:</span>
                            {isEditing ? (
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            ) : (
                                <span>{name}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Second Name:</span>
                            {isEditing ? (
                                <input type="text" value={secondName} onChange={(e) => setSecondName(e.target.value)} />
                            ) : (
                                <span>{secondName}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Parent Name:</span>
                            {isEditing ? (
                                <input type="text" value={parentName} onChange={(e) => setParentName(e.target.value)} />
                            ) : (
                                <span>{parentName}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Parent Phone:</span>
                            {isEditing ? (
                                <input type="text" value={parentPhone} onChange={(e) => setParentPhone(e.target.value)} />
                            ) : (
                                <span>{parentPhone}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Parent Email:</span>
                            {isEditing ? (
                                <input type="text" value={parentEmail} onChange={(e) => setParentEmail(e.target.value)} />
                            ) : (
                                <span>{parentEmail}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Parent Address:</span>
                            {isEditing ? (
                                <input type="text" value={parentAddress} onChange={(e) => setParentAddress(e.target.value)} />
                            ) : (
                                <span>{parentAddress}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Student ID:</span>
                            {isEditing ? (
                                <input type="text" value={studentId} onChange={(e) => setStudentId(e.target.value)} />
                            ) : (
                                <span>{studentId}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Gender:</span>
                            {isEditing ? (
                                <input type="text" value={gender} onChange={(e) => setGender(e.target.value)} />
                            ) : (
                                <span>{gender}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Date of Birth:</span>
                            {isEditing ? (
                                <input type="text" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                            ) : (
                                <span>{dateOfBirth}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Class ID:</span>
                            {isEditing ? (
                                <input type="text" value={classId} onChange={(e) => setClassId(e.target.value)} />
                            ) : (
                                <span>{classId}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Account Status:</span>
                            {isEditing ? (
                                <input type="text" value={accountstatus} onChange={(e) => setAccountstatus(e.target.value)} />
                            ) : (
                                <span>{accountstatus}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Deliberation:</span>
                            {isEditing ? (
                                <input type="text" value={deliberation} onChange={(e) => setDeliberation(e.target.value)} />
                            ) : (
                                <span>{deliberation}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Midterm Average Marks:</span>
                            {isEditing ? (
                                <input type="text" value={midterageragemmarks} onChange={(e) => setMidterageragemmarks(e.target.value)} />
                            ) : (
                                <span>{midterageragemmarks}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Midterm Average Grade:</span>
                            {isEditing ? (
                                <input type="text" value={midtermaveragegrade} onChange={(e) => setMidtermaveragegrade(e.target.value)} />
                            ) : (
                                <span>{midtermaveragegrade}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Midterm Position in Class:</span>
                            {isEditing ? (
                                <input type="text" value={midtermpositioninclass} onChange={(e) => setMidtermpositioninclass(e.target.value)} />
                            ) : (
                                <span>{midtermpositioninclass}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Term Average Marks:</span>
                            {isEditing ? (
                                <input type="text" value={termaveragemarks} onChange={(e) => setTermaveragemarks(e.target.value)} />
                            ) : (
                                <span>{termaveragemarks}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Term Average Grade:</span>
                            {isEditing ? (
                                <input type="text" value={termaveragegrade} onChange={(e) => setTermaveragegrade(e.target.value)} />
                            ) : (
                                <span>{termaveragegrade}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">Term Position in Class:</span>
                            {isEditing ? (
                                <input type="text" value={termpositioninclass} onChange={(e) => setTermpositioninclass(e.target.value)} />
                            ) : (
                                <span>{termpositioninclass}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">End Year Average Marks:</span>
                            {isEditing ? (
                                <input type="text" value={endyearaveragemarks} onChange={(e) => setEndyearaveragemarks(e.target.value)} />
                            ) : (
                                <span>{endyearaveragemarks}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">End Year Average Grade:</span>
                            {isEditing ? (
                                <input type="text" value={endyearaveragegrade} onChange={(e) => setEndyearaveragegrade(e.target.value)} />
                            ) : (
                                <span>{endyearaveragegrade}</span>
                            )}
                        </div>
                        <div>
                            <span className="font-semibold">End Year Position in Class:</span>
                            {isEditing ? (
                                <input type="text" value={endyearpositioninclass} onChange={(e) => setEndyearpositioninclass(e.target.value)} />
                            ) : (
                                <span>{endyearpositioninclass}</span>
                            )}
                        </div>
                        <div className="text-center mt-4">
                            <button
                                className="bg-blue-500 text-white font-semibold px-4 py-2 rounded mr-2"
                                onClick={() => setIsEditing(!isEditing)}
                            >
                                {isEditing ? 'Save' : 'Edit'}
                            </button>
                        </div>
                    </>
                )}
            </div> */}
            <Admin>
                {loading && <div>Loading...</div>}
                {error && <div>Error occurred while fetching student.</div>}
                {!loading && !error && (
                    <div>
                        <h1 className='text-center font-bold'>About {name} {secondName}</h1>

                        <Modal
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            footer={null}
                            className="p-10"
                            width={700}
                        >
                            <h1 className="text-2xl text-center">Edit student</h1>
                            <form onSubmit={handleSubmit}>
                                {error && <p>{error}</p>}
                                <div className="mt-10 flex">
                                    <div>
                                        <label className="text-lg ">Student name</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={`${name} ${secondName}`}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Parent name"
                                        />
                                        <label className="text-lg ">Parent Phone</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={parentPhone}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Parent phone"
                                        />
                                        <label className="text-lg ">Parent Email</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={parentEmail}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Parent Email"
                                        />
                                        <label className="text-lg ">Parent Address</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={parentAddress}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Parent Address"
                                        />
                                        <label className="text-lg ">Parent name</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={parentName}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Parent name"
                                        />
                                    </div>
                                    <div className="ml-10">
                                        <label className="text-lg ">Student ID</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={studentId}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit student name"
                                        />
                                        <label className="text-lg ">DOB</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={dateOfBirth}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit DOB"
                                        />
                                        <label className="text-lg ">Class</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={classId}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Class"
                                        />
                                        <label className="text-lg "> status</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={accountstatus}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Parent status"
                                        />
                                        <label className="text-lg ">Gender</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={gender}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit gender"
                                        />
                                        <label className="text-lg ">Delibaration</label>
                                        <input
                                            className="w-64 p-2 ml-2 mb-5 mt-4 border rounded focus:outline-none focus:border-blue-500"
                                            type="text"
                                            value={deliberation}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Edit Delibaration"
                                        />
                                    </div>

                                </div>
                                <button
                                    className="bg-blue-500 ml-20 mb-5 mt-4 text-white font-semibold px-4 py-2 rounded mr-2"

                                >
                                    Save
                                </button>

                            </form>
                        </Modal>
                        <div className='flex bg-white w-full px-28 py-10 justify-between'>
                            <div className='flex mt-14'>
                                <div className='w-[10px] rounded-lg bg-[#00A9DB] h-[220px]'></div>
                                <div className='w-32 text-[#00A9DB]'><h1 className='pt-14 pl-4 text-lg font-normal'>{name} {secondName} {classId} {studentId} Profile Card</h1></div>
                            </div>
                            <div className=' bg-white  w-[563px] shadow-md'>
                                <div className=' bg-[#00A9DB] pt-5 pb-20 rounded-bl-[100px]'>
                                    <img src={studentImage} className='w-28 ml-[38%]  h-28 rounded-full ' alt="student image not found" />
                                    <h2 className='text-center text-lg font-normal text-white pt-5'>{name} {secondName}</h2>
                                </div>
                                <div className='bg-white w-[97%] h-10 ml-4 -mt-10 rounded-tr-[90px]' ></div>
                                <div>
                                    <h1 className='text-center pt-10'>Student Reg: {studentId}</h1>
                                    <div className='flex justify-between m-5'>
                                        <div className='w-[250px]  shadow-md px-2 pb-10'>
                                            <ul>
                                                <li className='pt-4'>parent: {parentName}</li>
                                                <input type="text" value={parentPhone} onChange={(e) => setParentPhone(e.target.value)} />
                                                <li className='pt-4'>p-phone: {parentPhone}</li>
                                                <li className='pt-4'>p-email: {parentEmail}</li>
                                                <li className='pt-4'>p-address: {parentAddress}</li>
                                                <li className='pt-4'>parent: Mukamana Rozeline</li>
                                            </ul>
                                        </div>
                                        <div className='w-[250px] shadow-md px-2 pb-10'>
                                            <ul>
                                                <li className='pt-4'>S-gender: {gender}</li>
                                                <li className='pt-4'>DOB: {dateOfBirth}</li>
                                                <li className='pt-4'>class name: {classId}</li>
                                                <li className='pt-4'>status: {accountstatus}</li>
                                                <li className='pt-4'>Grade: {endyearaveragegrade}</li>
                                                <li className='pt-4'>Deliberation: {deliberation}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="w-full p-2 mt-10 mb-5 text-white rounded-md"
                                    style={{ backgroundColor: "rgba(19, 61, 103, 0.9)" }}
                                    type="submit"
                                    onClick={showModal}
                                >Edit
                                </button>
                            </div>
                        </div>
                    </div>

                )}
            </Admin>
        </>


    );

}


export default EditStudent;