import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../utils/apiUtilis";
import Cookies from "js-cookie";

// Create a thunk for fetching data from the API
export const login = createAsyncThunk(
    "api/v1/user/login",
    async (user, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${BASE_URL}/api/v1/user/login`, user);
        // Cookies.set("email", response?.data?.user?.email, { expires: 7 }); // Expires after 7 days
        Cookies.set("email", response?.data?.user?.email, { expires: 2}); //, httpOnly: true, sameSite: "Lax"
        Cookies.set("token", response.data?.token, { expires: 2 });
        Cookies.set("role", response.data.roleId, { expires: 2 });
        const { email, password } = user;
        return {
          ...response.data,
          credentials: {
            email,
            password,
          },
        };
      } catch (error) {
        return rejectWithValue(error.response.data.message);
      }
    }
);

export const verify = createAsyncThunk(
    "api/v1/user/login/verifyotp",
    async (user, { rejectWithValue }) => {
      try {
        const response = await axios.post(
            `${BASE_URL}/api/v1/user/login/verifyotp`,
            user
        );
        Cookies.set("token", response?.data?.token, { expires: 2, secure: true }); //, httpOnly: true, sameSite: "Lax"
        Cookies.set("role", response?.data?.roleId, { expires: 2, secure: true }); //, httpOnly: true, sameSite: "Lax"
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data.message);
      }
    }
);

const loginSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    status: null,
    Error: null,
    loading: false,
    errors: false,
  },
  extraReducers: {
    [login.pending]: (state) => {
      // state.status = "loading.....";
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = "success";
      state.success=true;
    },
    [login.rejected]: (state, action) => {
      state.status = "failed";
      state.Error = action.payload;
      state.errors=true;
    },
    [verify.pending]: (state, action) => {
      state.status = "loading.....";
    },
    [verify.rejected]: (state, action) => {
      state.status = "failed";
      state.Error = action.payload;
    },
    [verify.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = "success";
    },
  },
});

export default loginSlice.reducer;
