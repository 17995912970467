import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../../../utils/apiUtilis.js";

export const getAllpayments = createAsyncThunk(
  "api/v1/admin/payment",
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/payment/all`);
      console.log("This is all payment response", response.data.data);
      return response.data;
    } catch (err) {
      console.log("This is error", err.response.data.message);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  errors: null,
};

const getAllPaymentSlice = createSlice({
  name: "getallpayment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllpayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllpayments.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.errors = null; // Reset errors on success
      })
      .addCase(getAllpayments.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export default getAllPaymentSlice.reducer;
