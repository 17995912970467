import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../src/pages/Home";
import StaffLogin from "./pages/Login/StaffLogin";
import ParentLogin from "./pages/Login/ParentLogin";
import StudentLogin from "./pages/Login/StudentLogin";
import ParentDashbord from "./Components/Dashboard/ParentDashboard";
import VerifyOtp from "./pages/Login/VerifyOtp";
import AddClass from "./Components/Administration/Academics/Class/Addclass";
import AddTerm from "./Components/Administration/Academics/Term and Midterms/Term/AddTerm";
import AddMidTerm from "./Components/Administration/Academics/Term and Midterms/Midterm/Admidterm";
import AddCourse from "./Components/Administration/Academics/Course/Addcourse";
import AddStudent from "./Components/Administration/Academics/Student/AddStudent";
import GetAllStudent from "./Components/Administration/Academics/Student/GetAllStudent";
import GetAllterms from "./Components/Administration/Academics/Term and Midterms/Term/GetAllterms";
import ListUser from "./pages/ListofUser";
import ListStudent from "./pages/ListofStudent";
import EditStudent from "./Components/editComponent/EditStudent";
import ListStudentInClass from "./pages/ListofStudentInClass";
import NotFound from "./Components/NotFound/NotFound";
import AddStaff from "./Components/Administration/Academics/Teachers/AddTeacher";
import ForgotPassword from "./pages/Auth/ForgotPassword.jsx";
import ResetPasswordForm from "./pages/Auth/ResetPasswordForm.jsx";
import { ToastContainer } from "react-toastify";
import AddStudentMarks from "./pages/Marks/AddStudentMarks";
import ListStudentMarks from "./pages/StudentMarks";
import ListStudentTableInClass from "./pages/StudentMarksTableInClass";
import VerifyParentOtp from "./pages/Login/VerifyParentOtp";
import ListStudentWithParent from "./pages/ParentWithStudent";
import Payment from "./Components/Dashboard/payment/Payment";
import TuitionPayment from "./Components/Dashboard/payment/TuitionPayment";
import DashboardContent from "./Components/admin/DashboardContent";
import ParentMainDash from "./Components/Dashboard/ParentMainDash";
import Message from "./Components/Dashboard/Messages/Message";
import DisplayMarks from "./Components/Administration/Academics/marks/DisplayMarks";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/staff/login" element={<StaffLogin />} />
        <Route path="/parent/login" element={<ParentLogin />} />
        <Route path="/student/login" element={<StudentLogin />} />
        <Route path="/parent/dashboard" element={<ParentDashbord />} />
        <Route path="/login/verifyOtp" element={<VerifyOtp />} />
        <Route path="/parent/login/verifyOtp" element={<VerifyParentOtp />} />
        <Route path="/admin/add/class" element={<AddClass />} />
        <Route path="/admin/add/course" element={<AddCourse />} />
        <Route path="/admin/add/term" element={<AddTerm />} />
        <Route path="/admin/add/midterm" element={<AddMidTerm />} />
        <Route path="/admin/add/staff/member" element={<AddStaff />} />
        <Route path="/admin/get/allterm" element={<GetAllterms />} />
        <Route path="/admin/add/student" element={<AddStudent />} />
        <Route path="/admin/student/marks" element={<DisplayMarks />} />

        <Route path="/admin/get/student" element={<GetAllStudent />} />
        <Route path="/listusers" element={<ListUser />} />
        <Route path="/listusers/:id" element={<ListUser />} />
        <Route path="/all/student/schools" element={<ListStudent />} />
        <Route path="/all/student/schools/:id" element={<EditStudent />} />
        <Route
          path="/all/student/schools/class/marks/:name/:id"
          element={<ListStudentTableInClass />}
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/auth/reset-password/:userId"
          element={<ResetPasswordForm />}
        />
        <Route
          path="/all/student/schools/class/:name/:id"
          element={<ListStudentInClass />}
        />
        <Route path="/all/student/marks" element={<ListStudentMarks />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/parent" element={<ParentMainDash />} />
        <Route
          path="/parent/with/students"
          element={<ListStudentWithParent />}
        />
        <Route path="/all/payment" element={<Payment />} />
        <Route path="/tuition/payment" element={<TuitionPayment />} />
        <Route path="/dashboard" element={<DashboardContent />} />
        <Route path="/message" element={<Message />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
