import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClasses } from "../../../../Features/Redux/Administration/Academics/Class/GetClasses.slice";
import { CirclesWithBar } from "react-loader-spinner";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import StudentTable from "../../../../pages/ListofStudentInClass/StudentTable"; // Import the StudentTable component

const cardColors = ["border-[#133d67]", "border-[#06abea]"];

export default function ClassesListForMarks() {
    const dispatch = useDispatch();
    const { data, loading, errors } = useSelector((state) => state.getclasses);
    const [selectedClassId, setSelectedClassId] = useState(null);

    useEffect(() => {
        dispatch(getClasses());
    }, [dispatch]);

    const handleClassClick = (classId) => {
        setSelectedClassId(classId);
    };

    if (loading) return <CirclesWithBar />;

    if (errors) return <p>Error: {errors}</p>;

    if (!data || data.length === 0) return <p>No classes found.</p>;

    return (
        <>
            <div className="bg-[#133d67] flex justify-center items-center m-4">
                <p className="text-lg text-white">
                    View Student By Classes
                </p>
            </div>
            <div className="flex flex-col items-start   ">
                {data.reduce((acc, classInfo, i) => {
                    const rowIndex = Math.floor(i / 4);
                    if (!acc[rowIndex]) {
                        acc[rowIndex] = [];
                    }

                    const colorIndex = i % cardColors.length;

                    acc[rowIndex].push(
                        <Link
                            to={`/all/student/schools/class/marks/${classInfo.name}/${classInfo.id}`}
                            key={classInfo.id}
                            className={`flex items-center  p-2 mb-4 rounded-lg border-l-8 ${
                                cardColors[colorIndex]
                            } bg-gray-100 h-20 w-60 mx-4 ml-2  `}
                            onClick={() => handleClassClick(classInfo.id)} // Add click handler
                        >
                            <div className="flex-2 flex flex-col text-xs">
                                <h3 className="text-[16px] font-medium ">{classInfo.name}</h3>
                                <p className="text-gray-600">{classInfo.teacher}</p>
                            </div>

                            <div className="flex space-x-2 mt-12  text-xs mr-12">
                                <button className="py-1 text-smll bg-[#06abea] text-white rounded hover:bg-blue-600 w-20 h-5 ">
                                    View Students
                                </button>

                                <button className=" py-1 text-smll bg-[#133d67] text-white rounded hover:bg-indigo-600 h-5 w-20 ">
                                    Add Student
                                </button>
                            </div>
                        </Link>
                    );
                    return acc;
                }, []).map((row, i) => (
                    <div key={i} className="flex flex-row  ">
                        {row}
                    </div>
                ))}

                {selectedClassId !== null && (
                    <StudentTable classId={selectedClassId} />
                )}
            </div>
        </>
    );
}
