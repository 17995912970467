import React, { useState } from "react";
import { Modal } from "antd";
const cardColors = ["border-blue-600", "border-blue-800"];
const GetAllterms = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {" "}
      <div className="p-4 w-64 ">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 focus:outline-none"
          onClick={showModal}
        >
          Add Term
        </button>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          className="p-10"
        >
          <h1 className="text-2xl text-center">Add Term</h1>
          <form className="">
            <div className="mt-10">
              <label className="pl-20 text-lg ">Term name</label>
              <input
                className="w-2/3 mx-20 mb-10 p-2 mt-2 border rounded focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="Enter term name"
              />
            </div>

            <button
              className="w-full p-2 mt-10 mb-5 text-white rounded-md"
              style={{ backgroundColor: "rgba(19, 61, 103, 0.9)" }}
            >
              Add
            </button>
          </form>
        </Modal>
      </div>
      <div className="flex flex-col items-start   ">
        <h1 className="text-center px-20">Terms</h1>
        <div
          className={`flex items-center  p-2 mb-4 rounded-lg border-l-4 border-blue-800 bg-gray-100 h-20 w-60 mx-4 ml-2  `}
        >
          <div className="flex-2 flex flex-col text-xs">
            <h3 className="text-lg font-medium text-smll">First Term</h3>
            <p className="text-gray-600">Mid Terms</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetAllterms;
