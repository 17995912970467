import logo from '../../assets/images/logo-bgremove.png'
import dashboard from "../../assets/images/Dashboard.svg"
import group from '../../assets/images/Group.svg'
import message from '../../assets/images/Message.svg'
import payment from "../../assets/images/Payments.svg"
import parent from "../../assets/images/Vector.svg"
import event from "../../assets/images/Events.svg"
import announcement from "../../assets/images/Announcement.svg"
import report from '../../assets/images/Report.svg'
import settings from '../../assets/images/Settings.svg'
import logout from "../../assets/images/Logout.svg"
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import GitHubIcon from '@mui/icons-material/GitHub';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import {MdOutlineMenuOpen,MdExpandMore} from "react-icons/md"
import {AiFillGithub} from "react-icons/ai"
import {IoMdNotificationsOutline} from "react-icons/io"
import {BiSearch} from "react-icons/bi"
import profile from "../../assets/images/profile.webp"
import Cookies from "js-cookie"
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

//get email from cookie
const email = Cookies.get("email")
const role = Cookies.get("role")

const Admin = ({ children }) => {
    return (
        <div className='admin '>
            <div className="admin-container">
                <div className="sidebar-container">
                    <div className="nav-logo padd-2">
                        <img src={logo} className='nav-logo--img' />
                        <h1 className='nav-logo--name'>P<span>&#9733;</span>M<span>&#9733;</span>S</h1>

                    </div>
                    <div className='dashboard'>
                        <img src={dashboard} alt="" />
                        <p> <a href="/dashboard">Dashboard</a></p>
                    </div>
                    <ul className='admin-links'>
                        <li>

                            <Accordion>
                                <AccordionSummary 
                                    expandIcon={<MdExpandMore size={25}/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className='disp'>
                                        <img src={group} alt="" />
                                        <Typography>Academics</Typography>
                                    </div>

                                </AccordionSummary>
                                <AccordionDetails className='acc'>
                                    <a href='/admin/add/student'>Add Student</a>
                                </AccordionDetails>
                            </Accordion>
                        </li>
                        <li>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<MdExpandMore size={25}/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className='disp'>
                                        <img src={group} alt="" />
                                        <Typography>Student Services</Typography>
                                    </div>

                                </AccordionSummary>
                                <AccordionDetails className='acc'>
                                    <a href='/all/student/marks'>Marks</a>
                                    <a href='/teacher/add/marks'>Add Marks</a>
                                    <a href='/admin/add/term'>Term</a>
                                    <a href="/admin/add/class">Class</a>
                                    <a href="/admin/add/course">Subject</a>
                                </AccordionDetails>
                            </Accordion>
                        </li>

                        <li>
                            <img src={parent} alt="" />
                            <a href="/all/student/schools">Students</a>
                        </li>
                        {role === "1" && ( // Check if the role is equal to 1
                            <li>
                                <img src={payment} alt="" />
                                <a href="/listusers">Staff</a>
                            </li>
                        )}
                        <li>
                            <img src={message} alt="" />
                            <a href="#">Claims</a>
                        </li>
                        <li>
                            <img src={message} alt="" />
                            <a href="/message">Messages</a>
                        </li>
                        {role === "1" && ( // Check if the role is equal to 1
                            <li>
                                <img src={payment} alt="" />
                                <a href="/all/payment">Payment</a>
                            </li>
                        )}
                        <li>
                            <img src={parent} alt="" />
                            <a href="/parent/with/students">Parents</a>
                        </li>
                        <li>
                            <img src={event} alt="" />
                            <a href="#">Events</a>
                        </li>
                        <li>
                            <img src={announcement} alt="" />
                            <a href="#">Announcement</a>
                        </li>
                        <li>
                            <img src={report} alt="" />
                            <a href="#">Report Insights</a>
                        </li>
                        <li>
                            <img src={settings} alt="" />
                            <a href="#">Settings</a>
                        </li>
                        <li>
                            <img src={logout} alt="" />
                            <a href="#">Logout</a>
                        </li>

                    </ul>
                </div>
                <div className="main-container">
                    <div className="navbar">
                        <MdOutlineMenuOpen size={25}/>
                        <Box sx={{ flexGrow: 1 }}>

                                    <Search className='bg-white w-[40%] shadow-lg ml-20'>
                                        <SearchIconWrapper>
                                            <BiSearch/>
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </Search>
                        </Box>
                        <AiFillGithub size={25}/>
                        <IoMdNotificationsOutline size={25}/>

                           <img
                      className="w-10 h-10 object-cover rounded-full mr-1 mt-2"
                      alt="Remy Sharp"
                      src={profile}
                    />
                           {/* </div> */}
                            

                        {/* </Stack> */}
                        <Typography>{email}</Typography>
                    </div>
                    <div className="main-content">
                        <div style={{ minHeight: "100vh" }}>
                            {children}
                        </div>

                    </div>
                </div>
            </div >
        </div >
    )
}
export default Admin