import axios from 'axios';
import { BASE_URL } from '../../../utils/apiUtilis';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

// Create a thunk for fetching data from the API
export const getAllParents = createAsyncThunk(
    'api/v1/parent',
    async (user, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/parent/allparent/with/students/`, user);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

const allParentwithStudentSlice = createSlice({
    name: 'parentstudents',
    initialState: {
        users: [],
        status: null,
        Error: null,
        loading: false,
        errors: false,
    }
    ,
    extraReducers: {
        [getAllParents.pending]: (state) => {
            state.loading = true;

        }
        ,
        [getAllParents.fulfilled]: (state, action) => {
            state.loading = false;
            state.users = action.payload;
            state.errors = false;
        },
        [getAllParents.rejected]: (state, action) => {
            state.loading = false;
            state.Error = action.payload;
            state.errors = true;
        }
    }
}
)

export default allParentwithStudentSlice.reducer;