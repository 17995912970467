import {createAsyncThunk,createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../../../../../utils/apiUtilis.js";

export const addmidterm = createAsyncThunk(
    "api/v1/administration/academics/term/midterm/addmidterm",
    async (midtermData, {rejectWithValue}) => {
        try {
            const response = await axios.post(
                `${BASE_URL}/api/v1/term/midterm/create`,
                midtermData
            );
            console.log("This is add midterm response", response.data.data)
            return response.data;

        } catch (err) {
            console.log("This is error", err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);
// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null,
}
const resetState = {
    data: [],
    loading: false,
    errors: null
}

const addmidtermSlice = createSlice({
    name: "addmidterm",
    initialState,
    extraReducers: {
        [addmidterm.pending]: (state) => {
            state.loading = true;
        },
        [addmidterm.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [addmidterm.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
            state.data= [];
        }
    }
}
);

export default addmidtermSlice.reducer;