import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../../../utils/apiUtilis.js";

export const getAllcontents = createAsyncThunk(
  "api/v1/admin",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/dashboard/admin/content`
      );
      console.log("dashboard content", response.data);
      return response.data;
    } catch (err) {
      console.log("This is error", err.response.data.message);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const initialState = {
  data: {},
  loading: false,
  errors: null,
};

const getAllContentSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllcontents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllcontents.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
        state.errors = null; // Reset errors on success
      })
      .addCase(getAllcontents.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export default getAllContentSlice.reducer;
