// slice for Adding Student class
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../../../utils/apiUtilis.js";
export const addCourse = createAsyncThunk(
    "api/v1/administration/academics/class",
    async (courseData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${BASE_URL}/api/v1/course/new/add`,
                courseData
            );
            console.log("This is response",response.data)
            return response.data;

        } catch (err) {
            console.log("This is error",err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null
};

const addCourseSlice = createSlice({
    name: "addcourse",
    initialState,
    extraReducers: {
        [addCourse.pending]: (state) => {
            state.loading = true;
        },
        [addCourse.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
        },
        [addCourse.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
        }
    }
});

export default addCourseSlice.reducer;