import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from '../../../utils/apiUtilis';
import Cookies from 'js-cookie';
import {toast} from "react-toastify";


export const tuitionPayment = createAsyncThunk(
    "api/v1/administration/academics/term/midterm/addmarks",
    //set authorization header

    async (marksData, {rejectWithValue}) => {
        try {
            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            const response = await axios.post(
                `${BASE_URL}/api/v1/payment/add`,
                marksData, config
            );
            console.log("This is add marks response", response.data.data)
            response.data ? toast.success("Tuition payed  successfully") : toast.warning("Tuition not payed");
            return response.data;

        } catch (err) {
            err
                ? toast.warning(err.response.data.message)
                : toast.error(err.message);
            console.log("This is error from marks", err.response.data.message)
            return rejectWithValue(err.response.data.error);
        }
    }

);

const initialState = {
    data: [],
    loading: false,
    errors: null,
    isfulfilled : false,
    isRejected : false,


}


const ParentPayTuitionSlice = createSlice({
        name: "tuitionPayment",
        initialState,
        extraReducers: {
            [tuitionPayment.pending]: (state) => {
                state.loading = true;

            }
            ,
            [tuitionPayment.fulfilled]: (state, action) => {
                state.data = action.payload;
                state.loading = false;
                state.status = "success";
                state.isfulfilled = true;
                state.errors = null;

            },
            [tuitionPayment.rejected]: (state, action) => {
                state.errors = action.payload;
                state.loading = false;
                state.status = "failed";
                state.isfulfilled = false;
                state.isRejected = true;
            }
        }
    }
);

export default ParentPayTuitionSlice.reducer;