import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addStudent } from '../../../../Features/Redux/Administration/Academics/Student/AddStudent.slice';
import { getClasses } from '../../../../Features/Redux/Administration/Academics/Class/GetClasses.slice';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Image } from "cloudinary-react";
import PhoneInput from 'react-phone-input-2';
import { MdPhone } from 'react-icons/md';
import "react-phone-input-2/lib/style.css";
import Admin from '../../../admin/admin';

export default function AddStudent() {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };
    let imageUrl;
    const dispatch = useDispatch();
    const { data: classesData, loading: classesLoading, errors: classesErrors } = useSelector(
        (state) => state.getclasses
    );
    const { data: studentData, loading: studentLoading, errors: studentErrors } = useSelector(
        (state) => state.addstudent
    );
    const navigate = useNavigate();
    // const [image, setImage] = useState('');
    const [image, setImage] = useState('')

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getClasses());
        console.log("This classes", classesData);
    }, [dispatch]);
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'gwkladqc');

        // Upload image to Cloudinary
        fetch('https://api.cloudinary.com/v1_1/dgcmsqndb/image/upload', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {

                imageUrl = `https://res.cloudinary.com/dgcmsqndb/image/upload/${data.public_id}`;
                setImage(imageUrl);
                console.log('Image URL:', imageUrl);

            })

            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };


    const onSubmit = (formData) => {
        const newStudentData = {
            ...formData,
            image
        };

        // Dispatch the addStudent action with the newStudentData
        dispatch(addStudent(newStudentData))
            .then((resp) => {
                // Reset the form
                // toast.success("New product added!");
            })
            .catch((err) => {
                console.log(err);
            });

        // After successful registration, you can navigate to a different page
        navigate('/all/student/schools'); // Replace '/success' with the path of the success page
    };


    return (
        <>

            <Admin>

                <div className="min-h-screen flex items-center justify-center mg-3rem">
                    <div className="bg-white p-8 shadow-md rounded-md max-w-screen-md w-full ">
                        {/*//max-w-screen-lg*/}
                        <div className="flex justify-center">
                            <h1 className="text-3xl font-semibold mb-6">Register Student</h1>
                        </div>
                        <div className="flex justify-center">
                            <img src={image} alt="Student Picture" className="w-32 h-32 rounded-full object-cover mb-4" />
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap">
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="name" className="block mb-1 font-medium">
                                    First Name:
                                </label>
                                <input
                                    {...register('name', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.name && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="secondname" className="block mb-1 font-medium">
                                    Last Name:
                                </label>
                                <input
                                    {...register('secondname', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.secondname && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentemail" className="block mb-1 font-medium">
                                    Parent Email:
                                </label>
                                <input
                                    {...register('parentemail', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.parentemail && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="gender" className="block mb-1 font-medium">
                                    Gender:
                                </label>
                                <select
                                    {...register('gender', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                >
                                    <option value="">Select gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                {errors.gender && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="dateofbirth" className="block mb-1 font-medium">
                                    Date of Birth:
                                </label>
                                <input
                                    type="date"
                                    {...register('birthdate', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                    max={new Date().toISOString().split('T')[0]}
                                />
                                {errors.dateofbirth && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentname" className="block mb-1 font-medium">
                                    Parent Name:
                                </label>
                                <input
                                    {...register('parentname', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.parentname && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentsecondname" className="block mb-1 font-medium">
                                    Parent Second Name:
                                </label>
                                <input
                                    {...register('parentsecondname', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.parentname && <span className="text-red-500">This field is required</span>}
                            </div>


                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentphone" className="block mb-1 font-medium">
                                    Parent Phone:
                                </label>
                                <Controller
                                    name="parentphone"
                                    control={control}
                                    rules={{ required: 'This field is required' }}
                                    render={({ field }) => (
                                        <div className="relative">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <MdPhone className="text-lg text-gray-500" />
                                            </span>
                                            <PhoneInput
                                                {...field}
                                                defaultCountry="RW"
                                                inputProps={{
                                                    className: `w-full pl-10 border rounded-md py-2 px-3 outline-none ${errors.parentphone ? 'ring-red-500' : 'focus:ring-[#133d67]'
                                                        }`,
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                {errors.parentphone && <span className="text-red-500">{errors.parentphone.message}</span>}
                            </div>


                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="parentlocation" className="block mb-1 font-medium">
                                    Parent Address:
                                </label>
                                <input
                                    {...register('parentlocation', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                />
                                {errors.parentaddress && (
                                    <span className="text-red-500">This field is required</span>
                                )}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="image" className="block mb-1 font-medium">
                                    Image:
                                </label>
                                <input
                                    type="file"
                                    {...register('image', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                    multiple
                                    onChange={handleImageUpload} // Call the handleImageUpload function when the file is selected
                                />
                                {errors.image && <span className="text-red-500">This field is required</span>}
                                {imageUrl && ( // Display the image only if imageUrl is not empty
                                    <div className="mt-2">
                                        <Image
                                            cloudName="your_cloudinary_cloud_name"
                                            publicId={imageUrl}
                                            width="150"
                                            height="150"
                                            crop="fill"
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="w-full md:w-1/2 px-2 mb-4">
                                <label htmlFor="Studentclass" className="block mb-1 font-medium">
                                    Class:
                                </label>
                                <select
                                    {...register('Studentclass', { required: true })}
                                    className="w-full border rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-[#133d67]"
                                >
                                    <option value="">Select a class</option>
                                    {classesData &&
                                        classesData.map((classItem) => (
                                            <option key={classItem.id} value={classItem.id}>
                                                {classItem.name}
                                            </option>
                                        ))}
                                </select>
                                {errors.Studentclass && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="flex justify-between items-center">
                                <button
                                    type="submit"
                                    className="bg-[#133d67] hover:bg-[#06a9eaec] text-white py-2 px-4 rounded-md shadow-md transition duration-300 ease-in-out"
                                >
                                    Register
                                </button>
                                <Link
                                    to="/dashboard"
                                    className="text-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                                >
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Admin>

        </>
    );
}
