import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom';
const StudentLogin = () => {
    return (
        <>
            <div className='login-container'>
                <Header />
                <div className='login-container--form'>
                    <div className='form-container'>
                        <form action="" className='form'>
                            <h2 className='form-title'>
                                Student Login
                            </h2>
                            <div className='group-label'>
                                <label htmlFor="email">Student REG No</label>
                                <input type="email" name="email" id="email" />
                            </div>

                            <div className='btn-container'> 
                                <button type='submit' className='btn btn-sign-in'>Sign In</button>
                            </div>
                            <div className='link-container'>
                                <a href='#' className='link link-forget'>Forgot password?</a>
                            </div>
                            <div className='link-container '>

                                <Link to="/staff/login">
                                <a href='' className='link link-parent'>Staff Login </a>
                                </Link>
                                <Link to="/parent/login">
                                <a href='' className='link link-student'>Parent Login </a>
                                </Link>

                            </div>


                        </form>
                    </div>
                </div>

            </div>
            <Footer />
        </>

    )
}
export default StudentLogin