import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EditModal from "../editComponent/EditModal"; // Import your modal component
import EditAccountStatus from "../editComponent/EditAccountStatus";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCog } from '@fortawesome/free-solid-svg-icons';
import EditStudent from "../editComponent/EditStudent";


const CircularImage = ({ imageUrl }) => {
  const imageStyle = {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    overflow: "hidden",
    margin: "0 auto",
  };

  const imgStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
      <div style={imageStyle}>
        <img src={imageUrl} alt="SID" style={imgStyle} />
      </div>
  );
};
export const COLUMNS = [
  {
    Header: "No.",
    accessor: "id",
    Cell: ({ row }) => row.index + 1, // Custom accessor to display count from 1
  },
  {
    Header:"Class",
    accessor:"Class.name",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
   Header: "Second Name",
    accessor: "secondname",
  },
  {
    Header: "Term avg (%)",
    accessor: "currenttermaverage",
  },
  {
    Header: "Term Grade",
    accessor: "currenttermgrade",
  },
  {
    Header: "Term Position",
    accessor: "currenttermposition",
  },
  {
    Header: "M.Term Avg(%)",
    accessor: "averageMarks",
  },
    {
        Header: "M.Term Grade",
        accessor: "averageGrade",
    },
  {
    Header: "M.Term Position",
    accessor: "currentmidtermposition"
  },
  {
    Header: "Annual AVG(%)",
    accessor: "annualtotalAverage"
  },

  {
    Header: "Annual Grade",
    accessor: "annualtotalGrade",
  },
  {
    Header: "Annual position",
    accessor: "position"
  },

  {
    Header: "Action",
    Cell: ({ row }) => {
      const [showModal, setShowModal] = useState(false);
      const [showAccountModal, setShowAccountModal] = useState(false);
      const navigate = useNavigate();
      const { parentemail, roleId, id } = row.original;

      const openModal = (id) => {
        setShowModal(true);
      };
      const openAccountStatusModal = (id) => {
        navigate(`/all/student/schools/${id}`);
        setShowAccountModal(true);
      };

      const closeModal = () => {
        setShowModal(false);
        setShowAccountModal(false);
        navigate(`/all/student/schools`);
      };

      return (
          <div className="action">
            {/*<FontAwesomeIcon*/}
            {/*    icon={faEdit}*/}
            {/*    className="text-[#06abea] cursor-pointer"*/}
            {/*    onClick={() => openModal(id)}*/}
            {/*/>*/}
            <FontAwesomeIcon
                icon={faCog}
                className="text-green-500 cursor-pointer"
                onClick={() => openAccountStatusModal(id)}
            />
            {showModal && (
                <EditModal
                    email={parentemail}
                    roleId={roleId}
                    id={id}
                    closeModal={closeModal}
                />
            )}
            {showAccountModal && (
                <EditStudent id={id} closeModal={closeModal} />
            )}
          </div>
      );
    },
  },
];

