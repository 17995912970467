import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../../../../utils/apiUtilis.js";


export const getallstudentinclass = createAsyncThunk(
    "api/v1/administration/academics/student/inclass/getallstudentinclass",
    async (classData, {rejectWithValue}) => {

                try {
                    const response = await axios.get(
                        `${BASE_URL}/api/v1/class/get/class/students/${classData}`
                    );
                    console.log("This is all student response from one class", response.data.data)
                    return response.data;

                } catch (err) {
                    console.log("This is error", err.response.data.message)
                    return rejectWithValue(err.response.data.message);
                }
    }
);

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null,
}
const resetState = {
    data: [],
    loading: false,
    errors: null
}

const getAllStudentInClassSlice = createSlice({
    name: "getallstudentinclass",
    initialState,
    extraReducers: {
        [getallstudentinclass.pending]: (state) => {
            state.loading = true;



        },
        [getallstudentinclass.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;


        },
        [getallstudentinclass.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
            state.data= [];
        },
    }
});

export default getAllStudentInClassSlice.reducer;