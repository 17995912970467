import React from 'react'
import ParentDash from '../ParentDashboard'
import profile from "../../../assets/images/profile.webp"
const users=[
    {
    profile:profile,
    role:"Head of School"
},
    {
    profile:profile,
    role:"Head of School"
},
    {
    profile:profile,
    role:"Head of School"
},
    {
    profile:profile,
    role:"Head of School"
},
    {
    profile:profile,
    role:"Head of School"
},
]
const Message = () => {

    
  return (
    <ParentDash>
    <div>
        <div className='flex gap-10 mt-10'>
            <div className='divide-y bg-white w-[30%] rounded-md'>
            {users.map((user)=>(

//  <div className='flex gap-4 bg-white  h-72 p-10 >
 <>
 <div className='flex gap-3 py-3 pl-3 hover:bg-gray-200'>
 <img src={user.profile} alt="profile" className='rounded-full w-8 h-8' />
 <span className='pt-2'>{user.role}</span>
 </div>
 </>
// </div>

            ))}
           </div>
            <div className='w-[65%] bg-gray-300 h-[30rem] rounded-md'>
                <div className='w-full bg-gray-400 text-white h-12 px-2 py-1 flex rounded-md' >
                <img src={profile} alt="profile" className='rounded-full w-8 h-8' />
 <span className='pt-1 pl-2'>head of department</span>
                   
                </div>
                <div className='w-[90%] bg-white p-2 m-3 h-20 rounded-md' >
                    <p>message recieved</p>
                   
                </div>
                <div className='w-auto bg-white m-3 p-2 h-auto rounded-md float-right' >
                    <p>message send</p>
                   
                </div>
                <div className='absolute flex top-[31rem] gap-2
                '>
                <textarea name="" id="" cols="80" rows="2" className=' ml-[1px]'></textarea>
                <button
            className=" py-2 px-5 mt-5 mb-5 text-white rounded-md"
            style={{ backgroundColor: "rgba(19, 61, 103, 0.9)" }}
            type="submit"
          >
            Send
          </button>
                </div>
                
            </div>

        </div>
        
        
    </div>
    </ParentDash>
  )
}

export default Message
