// import { useHistory } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {getAllstudents} from "../../../../Features/Redux/Administration/Academics/Student/GetAllStudent.slice";
import {CirclesWithBar} from "react-loader-spinner";
// import { useTable } from 'react-table';
import {useEffect} from "react";

const GetAllStudent = () => {
    const dispatch = useDispatch();


    const {data, loading, errors} = useSelector(state => state.getallstudent);

    useEffect(() => {
        dispatch(getAllstudents());

    }, [dispatch]);
    if (loading) return <p>
        <CirclesWithBar
            type="Oval"
            color="#06a9eaec"
            height={100}
            width={100}
        /></p>;
    if (errors) return <p>Error: {errors}</p>;

    // check for data before mapping over it
    if (!data || data.length === 0) return <p>No classes found.</p>;

    console.log ("This is all student", data);
    return (
        <div className="p-4 w-64">
            <h2 className="text-xl font-semibold mb-4">Students List</h2>
            <ul className="space-y-4">
                {data.map(student => (
                    <li key={student.id} className="flex items-center justify-between p-2 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors duration-200">
                        <span>{student.name}</span>
                        <button className="px-3 py-1 bg-[#133d67] text-white rounded hover:bg-[#133d67] focus:outline-none">View</button>
                    </li>

                ))}
            </ul>
        </div>
    );


}


export default GetAllStudent;

// import React, { useState, useEffect } from "react";
// import { useTable } from "react-table";
// import { useSelector, useDispatch } from "react-redux";
// import { getAllstudents } from "../../../../Features/Redux/Administration/Academics/Student/GetAllStudent.slice";
// import {CirclesWithBar} from "react-loader-spinner";
//
// const GetAllStudent = () => {
//     const dispatch = useDispatch();
//
//     // const { data: allStudent, loading: allStudentStatus, errors: allStudentError } = useSelector(
//     //     (state) => state.getallstudent
//     // );
//     const { data: allStudent, loading: allStudentStatus, errors: allStudentError } = useSelector(state => state.getallstudent);
//
//     useEffect(() => {
//         dispatch(getAllstudents());
//     }, [dispatch]);
//
//     // if (allStudentError) return <p>Error: {allStudentError}</p>;
//
//     // check for data before mapping over it
//     // if (!allStudent|| allStudent.length === 0) return <p>No classes found.</p>;
//     console.log ("This is all student", allStudent);
//     const columns = [
//         { Header: "SID", accessor: "SID" },
//         { Header: "Name", accessor: "name" },
//         { Header: "Second Name", accessor: "secondname" },
//         { Header: "Parent Email", accessor: "parentemail" },
//         { Header: "Class", accessor: "Class.name" },
//     ];
//
//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         rows,
//         prepareRow,
//     } = useTable({ columns, data: allStudent });
//     console.log ("This is all student", allStudent);
//     if (allStudentStatus)
//         return (
//             <p>
//                 <CirclesWithBar type="Oval" color="#06a9eaec" height={100} width={100} />
//             </p>
//         );
//     if (allStudentError) return <p>Error: {allStudentError}</p>;
//
//     // Add a check for data before using react-table
//     if (!allStudentStatus || allStudentStatus.length === 0) return <p>No students found.</p>;
//     return (
//         <div>
//             {allStudentStatus ? (
//                 <p>Loading...</p>
//             ) : allStudentError ? (
//                 <p>Error: {allStudentError.message}</p>
//             ) : allStudent && allStudent.length > 0 ? (
//                 <table {...getTableProps()}>
//                     <thead>
//                     {headerGroups.map((headerGroup) => (
//                         <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
//                             {headerGroup.headers.map((column) => (
//                                 <th key={column.id} {...column.getHeaderProps()}>{column.render("Header")}</th>
//                             ))}
//                         </tr>
//                     ))}
//                     </thead>
//                     <tbody {...getTableBodyProps()}>
//                     {rows.map((row) => {
//                         prepareRow(row);
//                         return (
//                             <tr  key={row.id} {...row.getRowProps()}>
//                                 {row.cells.map((cell) => (
//                                     <td  key={cell.column.id} {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                                 ))}
//                             </tr>
//                         );
//                     })}
//                     </tbody>
//                 </table>
//             ) : (
//                 <p>No data available.</p>
//             )}
//         </div>
//     );
// };
//
// export default GetAllStudent;

