import React, { useState } from 'react';
import Header from '../Components/Header';
import video from "../assets/videos/videoBg.mp4"
import '../sass/main.scss';
import Footer from "../Components/Footer";
import logo from '../assets/images/logo.jpeg';


const Home = () => {
    const [open, setOpen] = useState(false);
    const [nav, setNav] = useState(false);
    const [openLinks, setOpenLinks] = useState(false)


    const changeBackground = () => {
        if (window.scrollY >= 100) {
            setNav(true)
        } else {
            setNav(false)
        }
    }
    window.addEventListener('scroll', changeBackground)
    return (
        <>
            <div className="home-container">
                <div className="video-background">
                    <video muted autoPlay loop className="video" style={{ width: "100%", height: '100vh' }}>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="overlay">
                        <div className="overlay-content">

                            <div className="overlay-content--content">
                                <h1 className="welcome-text">Effortlessly keep track of your child's well-being and academic progress in real time. With our
                                    advanced technology, you can access a range of features, including:</h1>
                                <div className="list">
                                    <ul className="list-offer">
                                        <li>Attendance Monitoring</li>
                                        <li>Communication Logs</li>
                                        <li>Health and Safety Alerts</li>
                                        <li>Academic Progress Reports</li>


                                    </ul>
                                    <ul className="list-offer">
                                        <li>Event Reminders</li>
                                        <li>Student Location Tracking</li>
                                        <li>Behavioral Monitoring</li>
                                        <li>Customized Notifications</li>
                                    </ul>
                                    <ul className="list-offer">

                                        <li>Secure Access</li>
                                        <li>User-Friendly Interface</li>
                                        <li>Parental Collaboration</li>
                                        <li>Tuition Payment</li>
                                        <li>24/7 Support</li>

                                    </ul>
                                </div>

                                <ul className='btn-container mg-top-3rem'>

                                    <li><a href='#' className='btn btn-secondary'>Get Quote</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>


                </div>
                <nav className={nav ? 'nav active' : 'nav'}>
                    <div className="nav-logo">
                        <img src={logo} className='nav-logo--img' />
                        <h1 className='nav-logo--name'>P<span>&#9733;</span>M<span>&#9733;</span>S</h1>
                    </div>
                    <ul className={openLinks ? 'nav-links active' : 'nav-links'} onClick={() => setOpenLinks(false)}>
                        <li><a href='/' className='nav-link'>Home</a></li>
                        <li><a href='#' className='nav-link'>About</a></li>
                        <li><a href='#' className='nav-link'>Contact-Us</a></li>
                        <li><a href='#' className='nav-link'>Services</a></li>
                        <li><a href='#' className='nav-link'>Features</a></li>
                        <li><a href='#' className='nav-link'>Live Demo</a></li>
                        <li><a href='#' className='nav-link'>Support</a></li>
                        <li><a href='#' className='nav-link'>Docs</a></li>
                        <li><a href='#' className='nav-link'>FAQs</a></li>

                    </ul>
                    <ul className='btn-container'>
                        <li><a href='/staff/login' className='btn btn-transparent'>Login</a></li>
                    </ul>
                    <div className='menu-container' onClick={() => setOpenLinks(!openLinks)}>
                        <i className={openLinks ? "fa-solid fa-close" : "fa-solid fa-bars"} style={{ color: "#333" }}></i>

                    </div>
                </nav>


            </div>
            <Footer />


        </>

    )
}
export default Home