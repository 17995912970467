// import React from 'react'
// import ParentDash from './ParentDashboard'
// import { useSelector, useDispatch } from "react-redux";
// import {mystudents} from "../../Features/Redux/parent/ParentMyStudentSlice";
// import { useEffect } from 'react';
//
// const ParentMainDash = () => {
//     const dispatch = useDispatch();
//     const { data:mystudent, status,loading } = useSelector((state) => state.mystudents);
//     useEffect(() => {
//         dispatch(mystudents());
//       }
//       , [dispatch]);
//     console.log ("This is all my for parent students", mystudent);
//   return (
//     <div>
//       <ParentDash>
//         <h1 className='text-center font-bold text-lg pt-10'>Welcome to Parent Dashboard</h1>
//         <div className='flex mt-4 gap-3 flex-wrap'>
//         <div className="bg-white rounded-lg ml-10 w-[25rem] p-5 shadow-md border border-gray-300 ">
//
//              <div>
//             <h2 className="text-md text-[#0F0F47] font-bold pt-2 text-lg">Cyifuze Ange</h2>
//             </div>
//
//             <div className="flex flex-row gap-10">
//             <p className="text-black font-bold">
//             First Term Marks
//              </p>
//              <p>80%</p>
//              </div>
//             <div className="flex flex-row gap-6">
//             <p className="text-black font-bold">
//             Second Term Marks
//              </p>
//              <p>70%</p>
//              </div>
//             <div className="flex flex-row gap-14">
//             <p className="text-black font-bold">
//             Displine Marks
//              </p>
//              <p>90%</p>
//              </div>
//             <div className="flex flex-row gap-20">
//             <p className="text-black font-bold">
//             Comments:
//              </p>
//              <p>Keep it up</p>
//              </div>
//
//             <div className="flex justify-between mb-1">
//   <span className="text-base font-medium text-blue-700 dark:text-white">OverAll progress </span>
//   <span className="text-sm font-medium text-blue-700 dark:text-white">85%</span>
// </div>
// <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
//   <div className="bg-blue-600 h-2.5 rounded-full" style={{width:"85%"}}></div>
// </div>
//             </div>
//         <div className="bg-white rounded-lg ml-10 w-[25rem] p-5 shadow-md border border-gray-300 ">
//
//              <div>
//             <h2 className="text-md text-[#0F0F47] font-bold pt-2 text-lg">Cyifuze Ange</h2>
//             </div>
//
//             <div className="flex flex-row gap-10">
//             <p className="text-black font-bold">
//             First Term Marks
//              </p>
//              <p>80%</p>
//              </div>
//             <div className="flex flex-row gap-6">
//             <p className="text-black font-bold">
//             Second Term Marks
//              </p>
//              <p>70%</p>
//              </div>
//             <div className="flex flex-row gap-14">
//             <p className="text-black font-bold">
//             Displine Marks
//              </p>
//              <p>90%</p>
//              </div>
//             <div className="flex flex-row gap-20">
//             <p className="text-black font-bold">
//             Comments:
//              </p>
//              <p>Keep it up</p>
//              </div>
//
//             <div className="flex justify-between mb-1">
//   <span className="text-base font-medium text-blue-700 dark:text-white">OverAll progress </span>
//   <span className="text-sm font-medium text-blue-700 dark:text-white">85%</span>
// </div>
// <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
//   <div className="bg-blue-600 h-2.5 rounded-full" style={{width:"85%"}}></div>
// </div>
//             </div>
//         </div>
//         <h1 className='text-center font-bold text-lg pt-10'>Agence Announcements</h1>
//         <div className='bg-white p-10 w-11/12 rounded-md m-10 h-72'>
//             <ul>
//                 <li>1. Hello Dear parents we Would like to Announce that we have agence meeting which will take place at 11/10/2023 at 11:00Am. <br /> N.B: Attendance is must!!</li>
//             </ul>
//
//         </div>
//       </ParentDash>
//     </div>
//   )
// }
//
// export default ParentMainDash
import React from 'react'
import ParentDash from './ParentDashboard'
import { useSelector, useDispatch } from "react-redux";
import { mystudents } from "../../Features/Redux/parent/ParentMyStudentSlice";
import { useEffect } from 'react';

const ParentMainDash = () => {
    const dispatch = useDispatch();
    const { data: mystudent, status, loading } = useSelector((state) => state.mystudents);

    useEffect(() => {
        dispatch(mystudents());
    }, [dispatch]);

    console.log("This is all my for parent students", mystudent);

    return (
        <div>
            <ParentDash>
                <h1 className='text-center font-bold text-lg pt-10'>Welcome to Parent Dashboard</h1>
                <div className='flex mt-4 gap-3 flex-wrap'>
                    {mystudent.map((student) => (
                        <div key={student.id} className="bg-white rounded-lg ml-10 w-[25rem] p-5 shadow-md border border-gray-300">
                            <div>
                                <h2 className="text-md text-[#0F0F47] font-bold pt-2 text-lg">{student.name} {student.secondname}</h2>
                            </div>
                            <div className="flex flex-row gap-10">
                                <p className="text-black font-bold">
                                    Current Term Marks
                                </p>
                                <p>{student.currenttermaverage}%</p>
                            </div>
                            <div className="flex flex-row gap-6">
                                <p className="text-black font-bold">
                                    Current Midterm Marks
                                </p>
                                <p>{student.averageMarks}%</p>
                            </div>
                            <div className="flex flex-row gap-14">
                                <p className="text-black font-bold">
                                    Class
                                </p>
                                <p>{student.Class.name} </p>
                            </div>
                            <div className="flex flex-row gap-14">
                                <p className="text-black font-bold">
                                    Student ID
                                </p>
                                <p>{student.SID} </p>
                            </div>

                            <div className="flex flex-row gap-20">
                                <p className="text-black font-bold">
                                    Comments:
                                </p>
                                <p>{student.deliberation}</p>
                            </div>
                            <div className="flex justify-between mb-1">
                                <span className="text-base font-medium text-blue-700 dark:text-white">Overall progress </span>
                                <span className="text-sm font-medium text-blue-700 dark:text-white">{student.annualtotalAverage}%</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${student.annualtotalAverage}%` }}></div>
                            </div>
                        </div>
                    ))}
                </div>
                <h1 className='text-center font-bold text-lg pt-10'>Agency Announcements</h1>
                <div className='bg-white p-10 w-11/12 rounded-md m-10 h-72'>
                    <ul>
                        <li>1. Hello Dear parents, we would like to announce that we have an agency meeting which will take place on 11/10/2023 at 11:00 AM. <br /> N.B: Attendance is a must!!</li>
                    </ul>
                </div>
            </ParentDash>
        </div>
    )
}

export default ParentMainDash;
