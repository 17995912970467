import React, { useState } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import UsePasswordToggle from "./usePasswordToggle";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Features/Redux/User/loginSlice';
import jwt from 'jwt-decode';
import Cookies from 'js-cookie';
import { CirclesWithBar } from 'react-loader-spinner';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";



const StaffLogin = () => {
    const [PasswordInputType, ToggleIcon] = UsePasswordToggle();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const dispatch = useDispatch();
    const { status, Error } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false); // Local state for spinner
    const [showError, setShowError] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const validateEmail = () => {
        if (!email) {
            setEmailError("Email is required.");
            setIsFormValid(false);
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Invalid email format.");
            setIsFormValid(false);
        } else {
            setEmailError("");
            setIsFormValid(!passwordError && password);
        }
    };

    const validatePassword = () => {
        if (!password) {
            setPasswordError("Password is required.");
            setIsFormValid(false);
        } else if (password.length < 8) {
            setPasswordError("Shorter than expected.");
            setIsFormValid(false);
        } else {
            setPasswordError("");
            setIsFormValid(!emailError && email);
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        validateEmail();
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePassword();
    };

    const handleSnackbarClose = () => {
        setShowSnackbar(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        validateEmail();
        validatePassword();

        if (!emailError && !passwordError) {
            setIsLoading(true); // Set isLoading to true before dispatching login action

            dispatch(login({ email, password }))
                .then((response) => {
                    if (response && response.payload.message === "Please enter your OTP") {
                        setIsLoggedIn(true);
                        navigate("/login/verifyotp");
                    }
                    if (response && response.payload.message === "Login successful") {
                        const token = Cookies.get("token");
                        const userCredential = jwt(token);
                        const userIn = JSON.stringify(userCredential);
                        setIsLoggedIn(true);
                        navigate("/dashboard");
                    } else {
                        setIsLoggedIn(false);
                        setShowSnackbar(true);
                    }
                })
                .catch((error) => {
                    console.log("Login error:", Error);
                    setIsLoggedIn(false);
                    setShowError(true);
                })
                .finally(() => {
                    setIsLoading(false); // Set isLoading back to false after handling response
                });
        }
        console.log("this is login error",Error)
    };

    return (
        <>
            <div className='login-container'>
                <Header />
                <div className='login-container--form'>
                    <div className='form-container'>
                        <form action="" className='form' onSubmit={handleSubmit}>
                            <h2 className='form-title'>
                                Welcome
                            </h2>
                            <div className='group-label'>
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                {emailError && <div className="error">{emailError}</div>}
                            </div>
                            <div className='group-label'>
                                <label htmlFor="password">Password</label>
                                <div className='passWord-field'>
                                    <input
                                        type={PasswordInputType}
                                        name="password"
                                        id="password"
                                        className='password'
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    {passwordError && <div className="error">{passwordError}</div>}
                                    <span className="password-toggle-icon">{ToggleIcon}</span>
                                </div>
                            </div>
                            <div className='btn-container'>
                                <button
                                    type="submit"
                                    className="btn btn-sign-in"
                                    disabled={!isFormValid}
                                >
                                    {isLoading ? ( // Use local isLoading state for spinner
                                        <CirclesWithBar
                                            type="Oval"
                                            color="#fff"
                                            height={45}
                                            width={45}
                                        />
                                    ) : (
                                        "Sign In"
                                    )}
                                </button>
                                {/*{showError && <div className="error">{Error}</div>}*/}
                                <div>
                                {status === "failed" && (
                                    <div className="signup-right">
                                        <Snackbar
                                            open={showSnackbar}
                                            autoHideDuration={6000}
                                            onClose={handleSnackbarClose}
                                        >
                                            <Alert
                                                onClose={handleSnackbarClose}
                                                severity="error"
                                                sx={{ width: "100%", fontSize: "1.5rem" }}
                                            >
                                                {Error}
                                            </Alert>
                                        </Snackbar>
                                    </div>
                                )}
                            </div>
                            </div>
                            <div className='link-container'>
                                <Link to="/auth/forgot-password" className="forgetText">
                                <a href='#' className='link link-forget'>Forgot password?</a>
                                </Link>
                            </div>
                            <div className='link-container '>
                                <Link to="/parent/login">
                                    <a href='' className='link link-parent'>Parent Login</a>
                                </Link>
                                <Link to="/student/login">
                                    <a href='' className='link link-student'>Student Login</a>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default StaffLogin;
