import UserTable from "./UserTable";
import "../../styles/interface.scss";
import Admin from "../../Components/admin/admin";

function ListUser() {
    return (
        <Admin>
            <p className="text-lg font-semibold">List of Staff</p>

            <div className="bg-[#133d67] flex justify-center items-center">
                <p className="text-lg text-white">
                    Manage all All Staff Members
                </p>
            </div>
            <UserTable />

        </Admin>
    );
}

export default ListUser;
