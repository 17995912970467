import logo from '../../assets/images/logo-bgremove.png'
import dashboard from "../../assets/images/Dashboard.svg"
import group from '../../assets/images/Group.svg'
import message from '../../assets/images/Message.svg'
import payment from "../../assets/images/Payments.svg"
import parent from "../../assets/images/Vector.svg"
import event from "../../assets/images/Events.svg"
import announcement from "../../assets/images/Announcement.svg"
import report from '../../assets/images/Report.svg'
import settings from '../../assets/images/Settings.svg'
import logout from "../../assets/images/Logout.svg"
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {MdOutlineMenuOpen,MdExpandMore} from "react-icons/md"
import {IoMdNotificationsOutline} from "react-icons/io"
import profile from "../../assets/images/profile.webp"
import Cookies from "js-cookie"

const ParentDash = ({ children }) => {
    const email = Cookies.get("email")
    return (
        <div className='admin'>
            <div className="admin-container">
                <div className="sidebar-container">
                    <div className="nav-logo padd-2">
                        <img src={logo} className='nav-logo--img' />
                        <h1 className='nav-logo--name'>P<span>&#9733;</span>M<span>&#9733;</span>S</h1>

                    </div>
                    <div className='dashboard'>
                        <img src={dashboard} alt="" />
                        <p> <a href="/parent">Dashboard</a></p>
                    </div>
                    <ul className='admin-links'>
                        <li>
                            <img src={group} alt="" />
                            <a href="#">Students Services</a>
                        </li>
                        <li>
                            <img src={message} alt="" />
                            <a href="/message">Claims</a>
                        </li>
                        <li>
                            <img src={message} alt="" />
                            <a href="/message">Messages</a>
                        </li>
                        <li>
                            <img src={payment} alt="" />
                            <a href="/tuition/payment">Payment</a>
                        </li>
                        <li>
                            <img src={parent} alt="" />
                            <a href="#">Parents</a>
                        </li>
                        <li>
                            <img src={event} alt="" />
                            <a href="#">Events</a>
                        </li>
                        <li>
                            <img src={announcement} alt="" />
                            <a href="#">Announcement</a>
                        </li>
                        <li>
                            <img src={report} alt="" />
                            <a href="#">Report Insights</a>
                        </li>
                        <li>
                            <img src={settings} alt="" />
                            <a href="#">Settings</a>
                        </li>
                        <li>
                            <img src={logout} alt="" />
                            <a href="#">Logout</a>
                        </li>

                    </ul>
                </div>
                <div className="main-container">
                    <div className="navbar">
                        <MdOutlineMenuOpen size={25}/>
                        <Box sx={{ flexGrow: 1 }}>
                        </Box>
                        <div className=' mr-20 flex  justify-between'>
                        <IoMdNotificationsOutline size={32} className='mt-[3px] mr-2'/>
                       <div className='flex'>
                           <img
                      className="w-10 h-10 object-cover rounded-full "
                      alt="Remy Sharp"
                      src={profile}
                    />
                        <Typography className='pt-2'>{email} </Typography>
                        </div>
                        </div>
                    </div>
                    <div className="main-content">
                        <div style={{ minHeight: "100vh" }}>
                            {children}
                        </div>

                    </div>
                </div>
            </div >
        </div >
    )
}
export default ParentDash