import React,{useEffect,useState} from 'react'
import Admin from './admin'
import parent from "../../assets/images/Vector.svg"
// import {LiaLandmarkSolid} from "react-icons/li"
import {PiStudentDuotone} from "react-icons/pi"
import BarCharts from './BarCharts'
import PieChrt from './PieChart'
import {useSelector, useDispatch} from "react-redux";
import { getAllcontents } from '../../Features/Redux/Administration/Academics/Student/dashContent'
const DashboardContent = () => {
  const dispatch = useDispatch();
  const { data, status,loading } = useSelector((state) => state.dashboard);
    // const userData = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(getAllcontents());
  }, [dispatch]);
console.log ("This is contents", data.user);
  return (
    <Admin>
    <div>
      <div className='flex  flex-wrap gap-2'>
      <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
            <div className="flex flex-row justify-between">
             <div>
            <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total Students</h2>
            </div>
            <div className='bg-blue-500 w-7 p-5 rounded-full'>
            </div>
            </div>
            <p className="text-black pt-1 font-bold">
                {data.student}
             </p>
            <div className="flex justify-between mb-1">
  <span className="text-base font-medium text-blue-700 dark:text-white">Compared to previous </span>
  <span className="text-sm font-medium text-blue-700 dark:text-white">85%</span>
</div>
<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
  <div className="bg-blue-600 h-2.5 rounded-full" style={{width:data.student}}></div>
</div>
            </div>
      <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
            <div className="flex flex-row justify-between">
             <div>
            <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total Staff</h2>
            </div>
            <div className='bg-red-500 w-7 p-5 rounded-full'>
            </div>
            </div>
            <p className="text-black pt-1 font-bold">
                {data.user}
             </p>
            <div className="flex justify-between mb-1">
  <span className="text-base font-medium text-red-700 dark:text-white">Compared to previous </span>
  <span className="text-sm font-medium text-red-700 dark:text-white">45%</span>
</div>
<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
  <div className="bg-red-600 h-2.5 rounded-full" style={{width:data.user}}></div>
</div>
            </div>
      <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
            <div className="flex flex-row justify-between">
             <div>
            <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total parent</h2>
            </div>
            <div className='bg-green-500 w-7 p-5 rounded-full'>
          <img src={parent} alt="" />
            </div>
            </div>
            <p className="text-black pt-1 font-bold">
                {data.parent}
             </p>
            <div className="flex justify-between mb-1">
  <span className="text-base font-medium text-green-700 dark:text-white">Compared to previous </span>
  <span className="text-sm font-medium text-green-700 dark:text-white">57%</span>
</div>
<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
  <div className="bg-green-600 h-2.5 rounded-full" style={{width:data.parent}}></div>
</div>
            </div>

          <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
              <div className="flex flex-row justify-between">
                  <div>
                      <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total Payment</h2>
                  </div>
                  <div className='bg-green-500 w-7 p-5 rounded-full'>
                      <img src={parent} alt="" />
                  </div>
              </div>
              <p className="text-black pt-1 font-bold">
                  {data.totalPayment}
              </p>
              <div className="flex justify-between mb-1">
                  <span className="text-base font-medium text-green-700 dark:text-white">Compared to previous </span>
                  <span className="text-sm font-medium text-green-700 dark:text-white">57%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div className="bg-green-600 h-2.5 rounded-full" style={{width:(data.totalPayment)/1000}}></div>
              </div>
          </div>

          <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
            <div className="flex flex-row justify-between">
             <div>
            <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total Announcement</h2>
            </div>
            <div className='bg-yellow-500 w-7 p-5 rounded-full'>
          <img src={parent} alt="" />
            </div>
            </div>
            <p className="text-black pt-1 font-bold">
            {data.announcement}
             </p>
            <div className="flex justify-between mb-1">
  <span className="text-base font-medium text-yellow-700 dark:text-white">Compared to previous </span>
  <span className="text-sm font-medium text-yellow-700 dark:text-white">25%</span>
</div>
<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
  <div className="bg-yellow-600 h-2.5 rounded-full" style={{width:data.announcement}}></div>
</div>
            </div>
          <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
              <div className="flex flex-row justify-between">
                  <div>
                      <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total Courses</h2>
                  </div>
                  <div className='bg-yellow-500 w-7 p-5 rounded-full'>
                      <img src={parent} alt="" />
                  </div>
              </div>
              <p className="text-black pt-1 font-bold">
                  {data.courses}
              </p>
              <div className="flex justify-between mb-1">
                  <span className="text-base font-medium text-yellow-700 dark:text-white">Compared to previous </span>
                  <span className="text-sm font-medium text-yellow-700 dark:text-white">25%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div className="bg-yellow-600 h-2.5 rounded-full" style={{width:data.courses}}></div>
              </div>
          </div>
          <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
              <div className="flex flex-row justify-between">
                  <div>
                      <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total Classes</h2>
                  </div>
                  <div className='bg-yellow-500 w-7 p-5 rounded-full'>
                      <img src={parent} alt="" />
                  </div>
              </div>
              <p className="text-black pt-1 font-bold">
                  {data.classes}
              </p>
              <div className="flex justify-between mb-1">
                  <span className="text-base font-medium text-yellow-700 dark:text-white">Compared to previous </span>
                  <span className="text-sm font-medium text-yellow-700 dark:text-white">25%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div className="bg-yellow-600 h-2.5 rounded-full" style={{width:data.classes}}></div>
              </div>
          </div>
          <div className="bg-white rounded-lg  w-64 p-5 shadow-md border border-gray-300 ">
              <div className="flex flex-row justify-between">
                  <div>
                      <h2 className="text-md text-[#0F0F47] font-poppins pt-3">Total Transactions</h2>
                  </div>
                  <div className='bg-yellow-500 w-7 p-5 rounded-full'>
                      <img src={parent} alt="" />
                  </div>
              </div>
              <p className="text-black pt-1 font-bold">
                  {data.payment}
              </p>
              <div className="flex justify-between mb-1">
                  <span className="text-base font-medium text-yellow-700 dark:text-white">Compared to previous </span>
                  <span className="text-sm font-medium text-yellow-700 dark:text-white">25%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div className="bg-yellow-600 h-2.5 rounded-full" style={{width:data.payment}}></div>
              </div>
          </div>
            </div>
            <div className='flex'>
            <BarCharts/>
            <PieChrt/>
            </div>
    </div>
    </Admin>
  )
}

export default DashboardContent
