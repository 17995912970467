// import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
// import axios from "axios";
// import {BASE_URL} from "../../../../../utils/apiUtilis.js";
//
//
// export const getAllstudents = createAsyncThunk(
//     "api/v1/administration/academics/student",
//     async (studentData, {rejectWithValue}) => {
//
//         try {
//             const response = await axios.get(
//                 `${BASE_URL}/api/v1/student/all/student/registered`
//             );
//             console.log("This is all student response", response.data.data)
//             return response.data.data;
//
//         } catch (err) {
//             console.log("This is error", err.response.data.message)
//             return rejectWithValue(err.response.data.message);
//         }
//     }
// );
//
// // extraReducers remains the same...
//
// const initialState = {
//     data: [],
//     loading: false,
//     errors: null
//
// };
//
// const getAllStudentSlice = createSlice({
//     name: "getallstudent",
//     initialState,
//     extraReducers: {
//         [getAllstudents.pending]: (state) => {
//             state.loading = true;
//
//         },
//         [getAllstudents.fulfilled]: (state, action) => {
//             state.data = action.payload;
//             state.loading = false;
//
//         },
//         [getAllstudents.rejected]: (state, action) => {
//             state.errors = action.payload;
//             state.loading = false;
//         },
//
//     }
// } );
//
// export default getAllStudentSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../../../utils/apiUtilis.js";

export const getAllstudents = createAsyncThunk(
    "api/v1/administration/academics/student",
    async (studentData, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/v1/student/all/student/registered`
            );
            console.log("This is all student response", response.data.data);
            return response.data;
        } catch (err) {
            console.log("This is error", err.response.data.message);
            return rejectWithValue(err.response.data.message);
        }
    }
);

const initialState = {
    data: [],
    loading: false,
    errors: null,
};

const getAllStudentSlice = createSlice({
    name: "getallstudent",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllstudents.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllstudents.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
                state.errors = null; // Reset errors on success
            })
            .addCase(getAllstudents.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            });
    },
});

export default getAllStudentSlice.reducer;

