import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import axios from "axios";
import {BASE_URL} from "../../../../../utils/apiUtilis.js";


export const addStudent = createAsyncThunk(
    "api/v1/administration/academics/student",
    async (studentData, {rejectWithValue}) => {
        try {
            const response = await axios.post(
                `${BASE_URL}/api/v1/student/register`,
                studentData
            );
            console.log("This is response", response.data)
            return response.data;

        } catch (err) {
            console.log("This is error", err.response.data.message)
            return rejectWithValue(err.response.data.message);
        }
    }
);

// extraReducers remains the same...

const initialState = {
    data: [],
    loading: false,
    errors: null
} ;

const addStudentSlice = createSlice({
    name: "addstudent",
    initialState,
    extraReducers: {
        [addStudent.pending]: (state) => {
            state.loading = true;

        },
        [addStudent.fulfilled]: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
        },
        [addStudent.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
        },

    }
} );

export default addStudentSlice.reducer;