import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import { BASE_URL } from '../../../../utils/apiUtilis';
import Cookies from 'js-cookie';
import {toast} from "react-toastify";


export const addmarks = createAsyncThunk(
    "api/v1/administration/academics/term/midterm/addmarks",
    //set authorization header

    async (marksData, {rejectWithValue}) => {
        try {
            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            const response = await axios.post(
                `${BASE_URL}/api/v1/marks/new/add`,
                marksData, config
            );
            console.log("This is add marks response", response.data.data)
            response.data ? toast.success("Marks added successfully") : toast.warning("Marks not added");
            return response.data;

        } catch (err) {
            err
                ? toast.warning(err.response.data.error)
                : toast.error(err.message);
            console.log("This is error from marks", err.response.data.error)
            return rejectWithValue(err.response.data.error);
        }
    }

);

const initialState = {
    data: [],
    loading: false,
    errors: null,
    isfulfilled : false,
    isRejected : false,


}


const addmarksSlice = createSlice({
    name: "addmarks",
    initialState,
    extraReducers: {
        [addmarks.pending]: (state) => {
            state.loading = true;

        }
        ,
        [addmarks.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.status = "success";
            state.isfulfilled = true;
            state.errors = null;

        },
        [addmarks.rejected]: (state, action) => {
            state.errors = action.payload;
            state.loading = false;
            state.status = "failed";
            state.isfulfilled = false;
            state.isRejected = true;
        }
    }
}
);

export default addmarksSlice.reducer;