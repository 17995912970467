import React ,{ useEffect, useMemo, useState }from 'react'
import ParentDash from '../ParentDashboard'
import { Table } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { getAllpayments } from '../../../Features/Redux/Administration/Academics/Tuition/GetAllTuition.slice';
import Admin from "../../admin/admin";

const columns= [
    {
      title: "Studenr Name",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Parent Name",
      dataIndex: "parent",
      key: "parent",
      
    },
    {
      title: "Amount paid",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Term",
      key: "Tuition",
      dataIndex: "Tuition",
    },
    {
      title: "Date of Payment",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
    },
  ];

const Payment = () => {
    const dataMapper= [];
    const [formData,setFormData]=useState(dataMapper)
    const dispatch = useDispatch();
    const { data: payments, status,loading } = useSelector((state) => state.getallpayment);
    useEffect(() => {
        dispatch(getAllpayments());
      }, [dispatch]);
    console.log ("This is all payment fromm the table", payments);

    const value = payments.data;
  useEffect(() => {
    value &&
      value.forEach((payment) => {
        dataMapper.push({
        student: `${payment?.Student?.name} ${payment?.Student?.secondname}`,
        parent: `${payment?.Parent?.name} ${payment?.Parent?.secondname}`,
        amount: payment?.Tuition?.amount,
        class: payment?.Student?.secondname,
        date: payment?.date?.substring(0, 10),
        Tuition: payment?.Tuition?.title,
        data: payment,
        });
      });

    setFormData(dataMapper);
  }, [payments]);
  return (
    <Admin>
     <Table columns={columns} dataSource={formData} pagination={false} loading={loading} />
    </Admin>
  )
}

export default Payment
